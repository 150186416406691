.c-breadcrumb {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  max-width: $max-width;
  padding: 0 17px;
  margin: 0 auto;

  @include sp {
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 15px;

    &:not(:last-of-type)::after {
      content: ">";
      padding-left: 15px;
    }

    @include sp {
      white-space: initial;
    }
  }

  &__link {
    padding: 3px 0;
  }
}
