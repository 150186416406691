.c-footer-nav {
  @include media-breakpoint-down(sm) {
    margin-bottom: 12px;
  }

  &__list {
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
    }
  }

  &__item {
    @include media-breakpoint-down(sm) {
      margin-bottom: 8px;
    }

    @include media-breakpoint-up(md) {
      margin-right: 31px;
    }
  }

  &__link {
    font-size: 1.2rem;
    letter-spacing: -0.6px;
    color: white !important;
    white-space: nowrap;
    padding: 3px 0;

    @include media-breakpoint-up(md) {
      font-size: 1.3rem;
    }
  }
}
