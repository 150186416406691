.p-top {

  &__sec {
    width: 100%;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }

    &__inner {
      width: 100%;
      max-width: $max-width;

      @include media-breakpoint-up(md) {
        padding: 0 20px;
      }
    }

    &.-kv {
      position: relative;

      @include media-breakpoint-up(md) {
        min-height: 430px;
        height: calc(100vh - 107px);
      }
    }

    &.-vision {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url('/assets/img/top/bg_vision-sp.jpg');
      padding: 38px 15px 40px;

      @include media-breakpoint-up(md) {
        height: 100vh;
        min-height: 530px;
        align-items: center;
        background-image: url('/assets/img/top/bg_vision.jpg');
        padding: 0 0 20px;
      }
    }

    &-vision {

      &__h2 {
        margin-bottom: 59px;

        @include media-breakpoint-up(md) {
          margin-bottom: 35px;
        }
      }

      &__h3 {
        font-size: 2.1rem;
        color: white;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
          font-size: 3.5rem;
        }
      }

      &__catch {
        font-size: 1.5rem;
        line-height: 1.6;
        color: white;
        margin-bottom: 23px;

        @include media-breakpoint-up(md) {
          font-size: 2.5rem;
          margin-bottom: 35px;
        }
      }

      &__msg {
        font-size: 1.3rem;
        color: white;
        margin-bottom: 36px;

        @include media-breakpoint-up(md) {
          font-size: 1.5rem;
          width: 580px;
          margin-bottom: 50px;
        }
      }

      &__btn {
      }
    }

    &.-news {
      padding: 40px 0 80px;

      @include media-breakpoint-up(md) {
        padding-top: 128px;
      }

      .p-top__sec__inner {
        @include media-breakpoint-down(sm) {
          padding: 0 15px;
        }
      }

      &__h2 {
        margin-bottom: 44px;

        @include media-breakpoint-up(md) {
          margin-bottom: 85px;
        }
      }
    }
  }

  &__pagination {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: calc(50% + 50px);
    right: 20px;
    transform: translateY(-50%);
    z-index: 100;
    padding: 0;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &__item {
      display: flex;
      margin: 17px 0;
    }

    &__link {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $dot-gray-color;
    }

    body[data-current-section='kv'] &__item:nth-of-type(1) &__link,
    body[data-current-section='vision'] &__item:nth-of-type(2) &__link,
    body[data-current-section='news'] &__item:nth-of-type(3) &__link {
      background-color: #fff;
      border: solid 1px #b3b3b3;
    }

    &__scroll-btn {
      margin-top: 36px;
      padding-left: 5px;

      &__img {
        width: 9px;
      }
    }
  }
}
