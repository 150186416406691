@mixin pc {
  @media screen and (min-width: $breakpoint-pc) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: $breakpoint-pc - 0.1) {
    @content;
  }
}

@mixin ie11 {
  @media all and (-ms-high-contrast: none) {

    *::-ms-backdrop,
    & {
      @content;
    }
  }
}

@mixin mincho {
  font-family:
    'source-han-serif-japanese',
    'Kozuka Mincho Pr6N',
    'Hiragino Mincho ProN',
    '游明朝',
    YuMincho,
    'ヒラギノ明朝 ProN W3',
    'HG明朝E',
    'ＭＳ Ｐ明朝',
    'ＭＳ 明朝',
    serif;
}
