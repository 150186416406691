.c-news-nav {
  max-width: 1055px;
  padding: 0;

  &__list {
    padding: 0;
  }

  &__item {
    border-bottom: solid 1px $border-light-gray-color;
  }

  a {

    &.c-news-nav__link {
      color: #007bff;
    }
  }

  &__link {
    width: 100%;
    display: flex;
    padding: 6px 0;

    @include media-breakpoint-up(md) {
      padding: 10px 0;
    }
  }

  &__date {
    width: 105px;
    min-width: 105px;
    white-space: nowrap;
    padding-left: 4px;

    @include media-breakpoint-up(md) {
      width: 128px;
      min-width: 128px;
    }
  }

  &__title {
  }
}
