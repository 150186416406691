.p-privacy {
  padding: 40px 0 80px;

  @include media-breakpoint-up(md) {
    padding: 100px 0 160px;
  }

  &__anchor-nav {
    margin-bottom: 42px;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      margin-bottom: 91px;
    }

    .c-btn:first-of-type {
      @include media-breakpoint-down(sm) {
        margin-bottom: 16px;
      }

      @include media-breakpoint-up(md) {
        margin-right: 42px;
      }
    }
  }

  &__signature {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 32px;

    @include media-breakpoint-up(md) {
      margin-bottom: 43px;
    }

    &__date {
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        margin-bottom: 39px;
      }
    }

    &__company {
      margin-bottom: 1px;

      @include media-breakpoint-up(md) {
        margin-bottom: 6px;
      }
    }

    &__president {}
  }

  &__sec {

    &.-privacy {
      margin-bottom: 39px;

      @include media-breakpoint-up(md) {
        margin-bottom: 87px;
      }
    }
  }

  &__h2 {
    font-size: 2.1rem;
    font-weight: bold;
    margin-bottom: 9px;

    @include media-breakpoint-up(md) {
      font-size: 3rem;
      margin-bottom: 29px;
    }
  }

  &__h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 13px;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
      margin-bottom: 30px;
    }
  }

  &__h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
      margin-bottom: 3px;
    }
  }

  &__paragraph {
    line-height: 1.8;
    margin-bottom: 33px;

    @include media-breakpoint-up(md) {
      line-height: 2.1;
      margin-bottom: 33px;
    }

    &.-description {
      margin-bottom: 24px;

      @include media-breakpoint-up(md) {
        margin-bottom: 36px;
      }
    }
  }

  &__dl {}

  &__dt {
    line-height: 1.8;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      line-height: 2.1;
      font-weight: bold;
    }
  }

  &__dd {
    line-height: 1.8;
    margin-bottom: 23px;

    @include media-breakpoint-up(md) {
      line-height: 2.1;
      margin-bottom: 33px;
    }
  }

  &__annot {
    font-size: 1.3rem;
    line-height: 1.8;

    @include media-breakpoint-up(md) {
      font-size: 1.4rem;
      line-height: 2.1;
    }
  }
}

.p-privacy__li {

  a {
    color: #007bff;
  }
}

.p-table__td {

  a {
    color: #007bff;
  }
}
