.c-mainvis {
  width: 100%;
  position: relative;

  @include media-breakpoint-up(md) {
    height: calc(100vh - 107px);
    min-height: 430px;
  }

  &__item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    &__inner {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: $max-width;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @include media-breakpoint-down(sm) {
      padding-top: 66.8%;

      &.-no01 {

        &:not(.-en) {
          background-image: url("/assets/img/top/kv01-sp.png");
        }

        &.-en {
          background-image: url("/assets/img/top/kv01-sp-en.png");
        }
      }

      &.-no02 {
        background-image: url("/assets/img/top/kv02-sp.jpg");
      }

      &.-no03 {
        background-image: url("/assets/img/top/kv03-sp.jpg");
      }

      &.-no04 {
        background-image: url("/assets/img/top/kv04-sp.jpg");
      }

      &.-no05 {
        background-image: url("/assets/img/top/kv05-sp.jpg");

        &.-en {
          background-image: url("/assets/img/top/kv05-en-sp.jpg");
        }
      }

      &.-no06 {
        background-image: url("/assets/img/top/kv06-sp.jpg");
      }
    }

    @include media-breakpoint-up(md) {

      &.-no01 {

        &:not(.-en) {
          background-image: url("/assets/img/top/kv01.png");
        }

        &.-en {
          background-image: url("/assets/img/top/kv01-en.png");
        }
      }

      &.-no02 {
        background-image: url("/assets/img/top/kv02.jpg");
        align-items: flex-end;
      }

      &.-no03 {
        background-image: url("/assets/img/top/kv03.jpg");
      }

      &.-no04 {
        background-image: url("/assets/img/top/kv04.jpg");
        align-items: flex-end;
      }

      &.-no05 {
        background-image: url("/assets/img/top/kv05.jpg");
        background-position-y: top;

        &.-en {
          background-image: url("/assets/img/top/kv05-en.jpg");
          background-position-y: top;
        }
      }

      &.-no06 {
        background-image: url("/assets/img/top/kv06.jpg");
        align-items: flex-end;
      }
    }
  }

  &__msg {
    width: 100%;
    max-width: $max-width;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.55;
    color: white;
    position: absolute;
    text-shadow: 5px 5px 5px #000;

    @include media-breakpoint-down(sm) {
      //display: none !important;
      font-size: 5.4vw;
      padding: 0 3.6%;
      margin: 0;

      &.-no02 {
        left: 0;
        bottom: 0;
        padding-bottom: 3.2%;
      }

      &.-no03 {
        left: 0;
        top: 0;
        font-size: 5.3vw;
        padding-top: 16.8%;
        padding-left: 6.3%;
      }

      &.-no04 {
        right: 0;
        bottom: 0;
        font-size: 5.3vw;
        padding-bottom: 5.5%;
        padding-right: 0;
      }

      &.-no05 {
        font-size: 0.9rem;
        bottom: 10px;
        text-align: right;
        right: 0;
      }
    }

    @include media-breakpoint-up(md) {
      padding: 0 20px;

      &.-no02 {
        left: 0;
        bottom: 105px;
      }

      &.-no03 {
        left: 0;
        top: 172px;
      }

      &.-no04 {
        padding-right: 56px;
        bottom: 113px;
        text-align: right;
        left: 0;

        span {
          display: inline-block;
          text-align: left;
        }
      }

      &.-no05 {
        font-size: 1.1rem;
        bottom: 10px;
        text-align: right;
        right: 0;
      }
    }
  }

  &__img {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  &__play-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    z-index: 11;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("/assets/img/common/icon_pause.svg");

    @include media-breakpoint-down(sm) {
      top: 10px;
    }

    @include media-breakpoint-up(md) {
      width: 50px;
      height: 50px;
      right: 50px;
      bottom: 60px;
    }

    &.-pause {
      background-image: url("/assets/img/common/icon_play.svg");
    }
  }

  .swiper-pagination-bullets {
    bottom: 50px !important;
  }

  .swiper-pagination-bullet {
    display: inline-flex;
    align-items: center;
    width: 80px;
    height: 30px;
    border-radius: inherit;
    background-color: transparent;
    opacity: 1;
    transition: 0.5s ease;

    &::after {
      content: "";
      width: 100%;
      height: 4px;
      background-color: white;
    }
  }

  .swiper-pagination-bullet-active {
    width: 120px;

    &::after {
      background-color: #666;
      height: 8px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: none;
    margin-top: -26px;

    @include media-breakpoint-up(md) {
      width: 50px;
      height: 50px;
      margin-top: -25px;
    }

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @include media-breakpoint-up(md) {
        width: 30px;
        height: 30px;
      }
    }
  }

  .swiper-button-prev {
    left: 0;

    @include media-breakpoint-up(md) {
      left: 30px;
    }

    &::after {
      background-image: url("/assets/img/common/icon_prev.svg");
    }
  }

  .swiper-button-next {
    right: 0;

    @include media-breakpoint-up(md) {
      right: 30px;
    }

    &::after {
      background-image: url("/assets/img/common/icon_next.svg");
    }
  }

  .swiper-pagination {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
