/* =============================================
 * Main
 * ============================================= */

.l-main {
  flex-grow: 1;

  &__inner {
    max-width: $max-width;
    padding: 0 17px;
    margin: 0 auto;
  }
}
