/* =============================================
 * Gnav
 * ============================================= */

.l-gnav {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  height: 42px;

  @include media-breakpoint-down(sm) {
    height: auto;
  }

  body.-nav-privacy & {
    border-bottom: solid 1px $border-light-gray2-color;
  }

  &__inner {
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $max-width;
    padding: 0 30px;

    @include media-breakpoint-down(sm) {
      height: 35px;
      padding: 0 10px 0 30px;
    }

    @include media-breakpoint-up(md) {
      padding: 0 20px;
    }
  }

  &__logo {
    width: 96px;
    display: flex;
    align-items: center;
    margin: 0;

    @include media-breakpoint-down(sm) {
      transform: translateY(2px);
    }

    @include media-breakpoint-up(md) {
      width: 116px;
    }

    &__link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__img {
    }
  }

  &__list {
    width: 795px;
    display: flex;
    padding: 0;
    margin: 0;

    &.__en {
      width: 950px;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    &.-sub {
      position: absolute;
      top: 42px;
      left: 0;
      flex-direction: column;
      width: 100%;
      height: 0;
      overflow: hidden;
      transition: all  0.3s ease-in-out;
    }
  }

  &__item {
    width: calc(100% / 5);

    &.-company {
      position: relative;
    }

    &.-product {
      position: relative;
    }

    &.-sub {
      display: block;
      background: #33a5db;
      color: #fff;
      z-index: 999;
      width: 100%;
    }
  }

  &__wrapper {
    display: flex;
  }

  &__link {
    height: 42px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 1.3rem;
    line-height: 1.4;
    font-weight: bold;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    opacity: 1 !important;
    text-align: center;
    color: #000;

    &.-contact {

      &::after {
        content: '';
        width: 1px;
        height: 26px;
        background-color: $border-gray-color;
        position: absolute;
        top: 50%;
        right: 0;
        visibility: visible;
        opacity: 1;
        transform: translateY(-50%);
        transition: all 0.3s ease-in-out;
      }
    }

    &.-sub {
      color: #fff;
    }

    &.-en {
      line-height: 1.2;
      width: 98px;
      height: 26px;
      background: #000;
      border: solid 1px #000;
      color: #fff;
      display: block;
      padding-top: 6px;

      &::before {
        content: none;
      }

      &:hover {
        background: #fff;
        color: #000;
      }

      &.v-pc {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &.h-pc {
        font-size: 1.3rem;
        margin: 0 8px 0 auto;
        width: 70px;
        height: 22px;
        padding-top: 3px;

        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }

    &::before {
      content: '';
      width: 1px;
      height: 26px;
      background-color: $border-gray-color;
      position: absolute;
      top: 50%;
      left: 0;
      visibility: visible;
      opacity: 1;
      transform: translateY(-50%);
      transition: all 0.3s ease-in-out;
    }
  }

  /* stylelint-disable */

  body.-nav-top &__item.-top &__link,
  body.-nav-company &__item.-company &__link,
  body.-nav-product &__item.-product &__link,
  body.-nav-contact &__item.-contact &__link,
  &__item:hover &__link {
    background-color: $theme-color;
    color: white;

    &::before {
      visibility: hidden;
      opacity: 0;
    }

    &::after {
      visibility: hidden;
      opacity: 0;
    }
  }

  .l-gnav__item:hover .l-gnav__link.-sub {
    background-color: #33a5db;
    color: white;
  }

  .l-gnav__item.-company:hover .l-gnav__list.-sub {
    height: auto;
  }

  .l-gnav__item.-product:hover .l-gnav__list.-sub {
    height: auto;
  }

  .l-gnav__item.-company:hover .l-gnav__link.-sub:hover {
    background-color: $theme-color;
  }

  .l-gnav__item.-product:hover .l-gnav__link.-sub:hover {
    background-color: $theme-color;
  }

  body.-nav-top &__item.-top + &__item.-product &__link::before,
  body.-nav-product &__item.-product + &__item.-company &__link::before,
  body.-nav-company &__item.-company + &__item.-contact &__link::before,
  &__item.-top:hover + &__item.-product &__link::before,
  &__item.-product:hover + &__item.-company &__link::before,
  &__item.-company:hover + &__item.-contact &__link::before {
    visibility: hidden;
    opacity: 0;
  }
}
