@charset "UTF-8";
/*!
 * Bootstrap Reboot v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* =============================================
 * Base
 * ============================================= */
html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Sans", "ヒラギノ角ゴシック", YuGothic, "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  line-height: 1.7;
  min-width: 320px;
  min-height: 100%; }
  @media (min-width: 768px) {
    body {
      min-width: 1090px;
      font-size: 1.6rem; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal; }

ul,
ol {
  list-style-type: none; }

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  vertical-align: top; }

a {
  color: #007bff;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.35s;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }
  a img {
    border: none; }
  a:hover {
    color: inherit;
    text-decoration: none;
    opacity: 0.85; }

/* =============================================
 * Header
 * ============================================= */
#tmpl_skiptxt {
  position: absolute;
  top: -9999px; }

#tmpl_skiptxt:focus,
#tmpl_skiptxt:hover {
  top: 0;
  color: #fff;
  z-index: 999; }

.l-header__en-link {
  font-size: 1.3rem;
  color: black !important;
  margin: 0 8px 0 auto; }
  @media (min-width: 768px) {
    .l-header__en-link {
      display: none; } }

/* =============================================
 * Gnav
 * ============================================= */
.l-gnav {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  height: 42px;
  /* stylelint-disable */ }
  @media (max-width: 767.98px) {
    .l-gnav {
      height: auto; } }
  body.-nav-privacy .l-gnav {
    border-bottom: solid 1px #ccc; }
  .l-gnav__inner {
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1230px;
    padding: 0 30px; }
    @media (max-width: 767.98px) {
      .l-gnav__inner {
        height: 35px;
        padding: 0 10px 0 30px; } }
    @media (min-width: 768px) {
      .l-gnav__inner {
        padding: 0 20px; } }
  .l-gnav__logo {
    width: 96px;
    display: flex;
    align-items: center;
    margin: 0; }
    @media (max-width: 767.98px) {
      .l-gnav__logo {
        transform: translateY(2px); } }
    @media (min-width: 768px) {
      .l-gnav__logo {
        width: 116px; } }
    .l-gnav__logo__link {
      display: flex;
      justify-content: center;
      align-items: center; }
  .l-gnav__list {
    width: 795px;
    display: flex;
    padding: 0;
    margin: 0; }
    .l-gnav__list.__en {
      width: 950px; }
    @media (max-width: 767.98px) {
      .l-gnav__list {
        display: none; } }
    .l-gnav__list.-sub {
      position: absolute;
      top: 42px;
      left: 0;
      flex-direction: column;
      width: 100%;
      height: 0;
      overflow: hidden;
      transition: all  0.3s ease-in-out; }
  .l-gnav__item {
    width: calc(100% / 5); }
    .l-gnav__item.-company {
      position: relative; }
    .l-gnav__item.-product {
      position: relative; }
    .l-gnav__item.-sub {
      display: block;
      background: #33a5db;
      color: #fff;
      z-index: 999;
      width: 100%; }
  .l-gnav__wrapper {
    display: flex; }
  .l-gnav__link {
    height: 42px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 1.3rem;
    line-height: 1.4;
    font-weight: bold;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    opacity: 1 !important;
    text-align: center;
    color: #000; }
    .l-gnav__link.-contact::after {
      content: '';
      width: 1px;
      height: 26px;
      background-color: #999;
      position: absolute;
      top: 50%;
      right: 0;
      visibility: visible;
      opacity: 1;
      transform: translateY(-50%);
      transition: all 0.3s ease-in-out; }
    .l-gnav__link.-sub {
      color: #fff; }
    .l-gnav__link.-en {
      line-height: 1.2;
      width: 98px;
      height: 26px;
      background: #000;
      border: solid 1px #000;
      color: #fff;
      display: block;
      padding-top: 6px; }
      .l-gnav__link.-en::before {
        content: none; }
      .l-gnav__link.-en:hover {
        background: #fff;
        color: #000; }
      @media (max-width: 767.98px) {
        .l-gnav__link.-en.v-pc {
          display: none; } }
      .l-gnav__link.-en.h-pc {
        font-size: 1.3rem;
        margin: 0 8px 0 auto;
        width: 70px;
        height: 22px;
        padding-top: 3px; }
        @media (min-width: 768px) {
          .l-gnav__link.-en.h-pc {
            display: none; } }
    .l-gnav__link::before {
      content: '';
      width: 1px;
      height: 26px;
      background-color: #999;
      position: absolute;
      top: 50%;
      left: 0;
      visibility: visible;
      opacity: 1;
      transform: translateY(-50%);
      transition: all 0.3s ease-in-out; }
  body.-nav-top .l-gnav__item.-top .l-gnav__link,
  body.-nav-company .l-gnav__item.-company .l-gnav__link,
  body.-nav-product .l-gnav__item.-product .l-gnav__link,
  body.-nav-contact .l-gnav__item.-contact .l-gnav__link,
  .l-gnav__item:hover .l-gnav__link {
    background-color: #008ed2;
    color: white; }
    body.-nav-top .l-gnav__item.-top .l-gnav__link::before,
    body.-nav-company .l-gnav__item.-company .l-gnav__link::before,
    body.-nav-product .l-gnav__item.-product .l-gnav__link::before,
    body.-nav-contact .l-gnav__item.-contact .l-gnav__link::before,
    .l-gnav__item:hover .l-gnav__link::before {
      visibility: hidden;
      opacity: 0; }
    body.-nav-top .l-gnav__item.-top .l-gnav__link::after,
    body.-nav-company .l-gnav__item.-company .l-gnav__link::after,
    body.-nav-product .l-gnav__item.-product .l-gnav__link::after,
    body.-nav-contact .l-gnav__item.-contact .l-gnav__link::after,
    .l-gnav__item:hover .l-gnav__link::after {
      visibility: hidden;
      opacity: 0; }
  .l-gnav .l-gnav__item:hover .l-gnav__link.-sub {
    background-color: #33a5db;
    color: white; }
  .l-gnav .l-gnav__item.-company:hover .l-gnav__list.-sub {
    height: auto; }
  .l-gnav .l-gnav__item.-product:hover .l-gnav__list.-sub {
    height: auto; }
  .l-gnav .l-gnav__item.-company:hover .l-gnav__link.-sub:hover {
    background-color: #008ed2; }
  .l-gnav .l-gnav__item.-product:hover .l-gnav__link.-sub:hover {
    background-color: #008ed2; }
  body.-nav-top .l-gnav__item.-top + .l-gnav__item.-product .l-gnav__link::before,
  body.-nav-product .l-gnav__item.-product + .l-gnav__item.-company .l-gnav__link::before,
  body.-nav-company .l-gnav__item.-company + .l-gnav__item.-contact .l-gnav__link::before,
  .l-gnav__item.-top:hover + .l-gnav__item.-product .l-gnav__link::before,
  .l-gnav__item.-product:hover + .l-gnav__item.-company .l-gnav__link::before,
  .l-gnav__item.-company:hover + .l-gnav__item.-contact .l-gnav__link::before {
    visibility: hidden;
    opacity: 0; }

/* =============================================
 * Main
 * ============================================= */
.l-main {
  flex-grow: 1; }
  .l-main__inner {
    max-width: 1230px;
    padding: 0 17px;
    margin: 0 auto; }

/* =============================================
 * Footer
 * ============================================= */
.l-footer {
  display: flex;
  justify-content: center;
  background-color: black;
  position: relative; }
  .l-footer__inner {
    width: 100%;
    max-width: 1230px;
    padding: 0 20px; }
    @media (max-width: 767.98px) {
      .l-footer__inner {
        padding: 19px 31px; } }
    @media (min-width: 768px) {
      .l-footer__inner {
        height: 60px;
        display: flex;
        padding-top: 12px; } }
  .l-footer__copy {
    font-size: 1rem;
    color: white;
    white-space: nowrap;
    padding-top: 5px;
    margin-left: auto; }
    @media (min-width: 768px) {
      .l-footer__copy {
        font-size: 1.2rem;
        letter-spacing: 0.4px; } }

.c-hamburger-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px; }
  @media (min-width: 768px) {
    .c-hamburger-btn {
      display: none; } }
  .c-hamburger-btn__bar {
    width: calc(100% - 25px);
    height: 2px;
    position: relative;
    background-color: black;
    border-radius: 2px;
    transition: all 0.3s; }
    body.is-opened--drawer-menu .c-hamburger-btn__bar {
      background-color: transparent; }
    .c-hamburger-btn__bar::before, .c-hamburger-btn__bar::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      background-color: black;
      border-radius: 2px;
      transition: all 0.3s; }
    .c-hamburger-btn__bar::before {
      top: -5px; }
      body.is-opened--drawer-menu .c-hamburger-btn__bar::before {
        top: 0;
        transform: rotate(225deg); }
    .c-hamburger-btn__bar::after {
      top: 5px; }
      body.is-opened--drawer-menu .c-hamburger-btn__bar::after {
        top: 0;
        transform: rotate(-225deg); }

.c-drawer-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 35px;
  left: 0;
  background-color: black;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  padding-bottom: 20px;
  z-index: 10; }
  @media (min-width: 768px) {
    .c-drawer-menu {
      display: none !important; } }
  body.is-opened--drawer-menu .c-drawer-menu {
    opacity: 1;
    visibility: visible; }
  .c-drawer-menu__title {
    display: flex;
    height: 35px;
    align-items: center;
    font-size: 1.3rem;
    letter-spacing: -0.6px;
    color: white;
    padding-left: 31px;
    margin-bottom: 4px; }
    @media screen and (max-width: 320px) {
      .c-drawer-menu__title {
        justify-content: center;
        padding-left: 0; } }
  .c-drawer-menu__list {
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px; }
  .c-drawer-menu__item:not(:last-of-type) {
    border-bottom: solid 1px white; }
  .c-drawer-menu__link {
    width: 100%;
    display: flex;
    align-items: center;
    height: 55px;
    position: relative;
    background-color: #212727;
    font-size: 1.6rem;
    color: white !important;
    white-space: nowrap;
    padding-left: 31px; }
    .c-drawer-menu__link::after {
      content: "";
      width: 10px;
      height: 13px;
      position: absolute;
      top: 50%;
      right: 25px;
      background: url("/assets/img/common/icon_arrow-right-white.svg") no-repeat center;
      background-size: contain;
      transform: translateY(-50%); }
    .c-drawer-menu__link.-accordion::after {
      transform: translateY(-50%) rotate(90deg); }
    .c-drawer-menu__link.-accordion.active::after {
      transform: translateY(-50%) rotate(-90deg); }
    .c-drawer-menu__link.-sub {
      padding-left: 50px; }
  .c-drawer-menu__site-link {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 170px;
    height: 35px;
    border: solid 1px white;
    color: white !important;
    white-space: nowrap; }
  .c-drawer-menu__accordion {
    margin-bottom: 0; }
    .c-drawer-menu__accordion dt {
      font-weight: normal; }
    .c-drawer-menu__accordion dd {
      display: none; }
      .c-drawer-menu__accordion dd ul {
        padding: 0; }

@media (max-width: 767.98px) {
  .c-footer-nav {
    margin-bottom: 12px; } }

.c-footer-nav__list {
  padding: 0;
  margin: 0; }
  @media (min-width: 768px) {
    .c-footer-nav__list {
      display: flex;
      align-items: center; } }

@media (max-width: 767.98px) {
  .c-footer-nav__item {
    margin-bottom: 8px; } }

@media (min-width: 768px) {
  .c-footer-nav__item {
    margin-right: 31px; } }

.c-footer-nav__link {
  font-size: 1.2rem;
  letter-spacing: -0.6px;
  color: white !important;
  white-space: nowrap;
  padding: 3px 0; }
  @media (min-width: 768px) {
    .c-footer-nav__link {
      font-size: 1.3rem; } }

.c-pageup {
  width: 100%;
  max-width: 1230px;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 14px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  padding: 0 24px; }
  .c-pageup.-static {
    position: absolute;
    top: -43px;
    bottom: inherit; }
    @media (min-width: 768px) {
      .c-pageup.-static {
        top: -50px; } }
  body.-visible-pageup .c-pageup {
    opacity: 1;
    visibility: visible; }
  @media (min-width: 768px) {
    .c-pageup {
      bottom: 20px;
      padding: 0 20px; } }
  .c-pageup__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    background-color: #333; }
    @media (min-width: 768px) {
      .c-pageup__link {
        width: 39px; } }

.c-sec-title {
  display: flex;
  align-items: flex-end; }
  .c-sec-title__main {
    font-size: 3rem;
    color: #008ed2;
    line-height: 1;
    margin-right: 14px;
    transform: translateY(5px); }
    @media (min-width: 768px) {
      .c-sec-title__main {
        font-size: 4rem;
        margin-right: 16px; } }
  .c-sec-title__main-img.-vision {
    width: 138px; }
    @media (min-width: 768px) {
      .c-sec-title__main-img.-vision {
        width: 170px;
        margin-right: 19px; } }
  .c-sec-title__main-img.-news {
    width: 124px; }
    @media (min-width: 768px) {
      .c-sec-title__main-img.-news {
        width: 152px;
        margin-right: 17px; } }
  .c-sec-title__sub {
    font-size: 1.5rem;
    line-height: 1; }
    @media (min-width: 768px) {
      .c-sec-title__sub {
        font-size: 2rem; } }
  @media (max-width: 767.98px) {
    .c-sec-title.-large {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .c-sec-title.-large .c-sec-title__main-img {
        margin-bottom: 18px; } }
  .c-sec-title.-white .c-sec-title__main {
    color: white; }
  .c-sec-title.-white .c-sec-title__sub {
    color: white; }
  .c-sec-title-under {
    font-size: 4rem;
    line-height: 1.75;
    padding-bottom: 40px;
    border-bottom: 1px solid #999; }
    .c-sec-title-under.-support {
      font-size: 3rem;
      font-weight: bold;
      border-bottom: none; }

.c-mainvis {
  width: 100%;
  position: relative; }
  @media (min-width: 768px) {
    .c-mainvis {
      height: calc(100vh - 107px);
      min-height: 430px; } }
  .c-mainvis__item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative; }
    .c-mainvis__item__inner {
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 1230px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%); }
    @media (max-width: 767.98px) {
      .c-mainvis__item {
        padding-top: 66.8%; }
        .c-mainvis__item.-no01:not(.-en) {
          background-image: url("/assets/img/top/kv01-sp.png"); }
        .c-mainvis__item.-no01.-en {
          background-image: url("/assets/img/top/kv01-sp-en.png"); }
        .c-mainvis__item.-no02 {
          background-image: url("/assets/img/top/kv02-sp.jpg"); }
        .c-mainvis__item.-no03 {
          background-image: url("/assets/img/top/kv03-sp.jpg"); }
        .c-mainvis__item.-no04 {
          background-image: url("/assets/img/top/kv04-sp.jpg"); }
        .c-mainvis__item.-no05 {
          background-image: url("/assets/img/top/kv05-sp.jpg"); }
          .c-mainvis__item.-no05.-en {
            background-image: url("/assets/img/top/kv05-en-sp.jpg"); }
        .c-mainvis__item.-no06 {
          background-image: url("/assets/img/top/kv06-sp.jpg"); } }
    @media (min-width: 768px) {
      .c-mainvis__item.-no01:not(.-en) {
        background-image: url("/assets/img/top/kv01.png"); }
      .c-mainvis__item.-no01.-en {
        background-image: url("/assets/img/top/kv01-en.png"); }
      .c-mainvis__item.-no02 {
        background-image: url("/assets/img/top/kv02.jpg");
        align-items: flex-end; }
      .c-mainvis__item.-no03 {
        background-image: url("/assets/img/top/kv03.jpg"); }
      .c-mainvis__item.-no04 {
        background-image: url("/assets/img/top/kv04.jpg");
        align-items: flex-end; }
      .c-mainvis__item.-no05 {
        background-image: url("/assets/img/top/kv05.jpg");
        background-position-y: top; }
        .c-mainvis__item.-no05.-en {
          background-image: url("/assets/img/top/kv05-en.jpg");
          background-position-y: top; }
      .c-mainvis__item.-no06 {
        background-image: url("/assets/img/top/kv06.jpg");
        align-items: flex-end; } }
  .c-mainvis__msg {
    width: 100%;
    max-width: 1230px;
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.55;
    color: white;
    position: absolute;
    text-shadow: 5px 5px 5px #000; }
    @media (max-width: 767.98px) {
      .c-mainvis__msg {
        font-size: 5.4vw;
        padding: 0 3.6%;
        margin: 0; }
        .c-mainvis__msg.-no02 {
          left: 0;
          bottom: 0;
          padding-bottom: 3.2%; }
        .c-mainvis__msg.-no03 {
          left: 0;
          top: 0;
          font-size: 5.3vw;
          padding-top: 16.8%;
          padding-left: 6.3%; }
        .c-mainvis__msg.-no04 {
          right: 0;
          bottom: 0;
          font-size: 5.3vw;
          padding-bottom: 5.5%;
          padding-right: 0; }
        .c-mainvis__msg.-no05 {
          font-size: 0.9rem;
          bottom: 10px;
          text-align: right;
          right: 0; } }
    @media (min-width: 768px) {
      .c-mainvis__msg {
        padding: 0 20px; }
        .c-mainvis__msg.-no02 {
          left: 0;
          bottom: 105px; }
        .c-mainvis__msg.-no03 {
          left: 0;
          top: 172px; }
        .c-mainvis__msg.-no04 {
          padding-right: 56px;
          bottom: 113px;
          text-align: right;
          left: 0; }
          .c-mainvis__msg.-no04 span {
            display: inline-block;
            text-align: left; }
        .c-mainvis__msg.-no05 {
          font-size: 1.1rem;
          bottom: 10px;
          text-align: right;
          right: 0; } }
  @media (min-width: 768px) {
    .c-mainvis__img {
      display: none; } }
  .c-mainvis__play-btn {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    z-index: 11;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("/assets/img/common/icon_pause.svg"); }
    @media (max-width: 767.98px) {
      .c-mainvis__play-btn {
        top: 10px; } }
    @media (min-width: 768px) {
      .c-mainvis__play-btn {
        width: 50px;
        height: 50px;
        right: 50px;
        bottom: 60px; } }
    .c-mainvis__play-btn.-pause {
      background-image: url("/assets/img/common/icon_play.svg"); }
  .c-mainvis .swiper-pagination-bullets {
    bottom: 50px !important; }
  .c-mainvis .swiper-pagination-bullet {
    display: inline-flex;
    align-items: center;
    width: 80px;
    height: 30px;
    border-radius: inherit;
    background-color: transparent;
    opacity: 1;
    transition: 0.5s ease; }
    .c-mainvis .swiper-pagination-bullet::after {
      content: "";
      width: 100%;
      height: 4px;
      background-color: white; }
  .c-mainvis .swiper-pagination-bullet-active {
    width: 120px; }
    .c-mainvis .swiper-pagination-bullet-active::after {
      background-color: #666;
      height: 8px; }
  .c-mainvis .swiper-button-prev,
  .c-mainvis .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: none;
    margin-top: -26px; }
    @media (min-width: 768px) {
      .c-mainvis .swiper-button-prev,
      .c-mainvis .swiper-button-next {
        width: 50px;
        height: 50px;
        margin-top: -25px; } }
    .c-mainvis .swiper-button-prev::after,
    .c-mainvis .swiper-button-next::after {
      content: "";
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; }
      @media (min-width: 768px) {
        .c-mainvis .swiper-button-prev::after,
        .c-mainvis .swiper-button-next::after {
          width: 30px;
          height: 30px; } }
  .c-mainvis .swiper-button-prev {
    left: 0; }
    @media (min-width: 768px) {
      .c-mainvis .swiper-button-prev {
        left: 30px; } }
    .c-mainvis .swiper-button-prev::after {
      background-image: url("/assets/img/common/icon_prev.svg"); }
  .c-mainvis .swiper-button-next {
    right: 0; }
    @media (min-width: 768px) {
      .c-mainvis .swiper-button-next {
        right: 30px; } }
    .c-mainvis .swiper-button-next::after {
      background-image: url("/assets/img/common/icon_next.svg"); }
  @media (max-width: 767.98px) {
    .c-mainvis .swiper-pagination {
      display: none; } }

.c-lower-mainvis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 158px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5px; }
  @media (min-width: 768px) {
    .c-lower-mainvis {
      height: 400px;
      padding-top: 20px; } }
  .c-lower-mainvis.-company {
    background-image: url("/assets/img/company/kv-sp.jpg"); }
    @media (min-width: 768px) {
      .c-lower-mainvis.-company {
        background-image: url("/assets/img/company/kv.jpg"); } }
  .c-lower-mainvis.-cpplus2021 {
    background-image: url("/assets/img/news/cpplus2021/kv.jpg");
    height: 502px; }
    @media (min-width: 768px) {
      .c-lower-mainvis.-cpplus2021 {
        background-image: url("/assets/img/news/cpplus2021/kv.jpg");
        height: 660px; } }
  .c-lower-mainvis.-product {
    background-image: url("/assets/img/product/kv-sp.jpg"); }
    @media (min-width: 768px) {
      .c-lower-mainvis.-product {
        background-image: url("/assets/img/product/kv.jpg");
        padding-right: 7px; } }
  .c-lower-mainvis__title {
    font-size: 2.4rem;
    color: white;
    text-shadow: 0 0 10px #000, 0 0 10px #000, 0 0 10px #000; }
    @media (min-width: 768px) {
      .c-lower-mainvis__title {
        font-size: 4rem; } }

.c-news-nav {
  max-width: 1055px;
  padding: 0; }
  .c-news-nav__list {
    padding: 0; }
  .c-news-nav__item {
    border-bottom: solid 1px #b3b3b3; }
  .c-news-nav a.c-news-nav__link {
    color: #007bff; }
  .c-news-nav__link {
    width: 100%;
    display: flex;
    padding: 6px 0; }
    @media (min-width: 768px) {
      .c-news-nav__link {
        padding: 10px 0; } }
  .c-news-nav__date {
    width: 105px;
    min-width: 105px;
    white-space: nowrap;
    padding-left: 4px; }
    @media (min-width: 768px) {
      .c-news-nav__date {
        width: 128px;
        min-width: 128px; } }

.c-btn {
  width: 100%;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: black;
  color: white !important;
  font-size: 1.6rem;
  white-space: nowrap; }
  @media (min-width: 768px) {
    .c-btn {
      width: 420px;
      height: 64px; } }
  .c-btn.-primary {
    background-color: #008ed2; }
  @media (max-width: 767.98px) {
    .c-btn.-top {
      height: 52px;
      font-size: 1.3rem; } }
  .c-btn::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: none; }
  .c-btn:not(.-anchor)::after {
    width: 8px;
    height: 11px;
    top: 50%;
    right: 15px;
    background-image: url("/assets/img/common/icon_arrow-right-white.svg");
    transform: translateY(-50%); }
    @media (min-width: 768px) {
      .c-btn:not(.-anchor)::after {
        width: 10px;
        height: 13px;
        right: 18px; } }
  .c-btn.-anchor {
    height: 85px;
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 15px; }
    @media (min-width: 768px) {
      .c-btn.-anchor {
        height: 104px;
        font-size: 2.3rem;
        padding-bottom: 17px; } }
    .c-btn.-anchor::after {
      width: 13px;
      height: 9px;
      bottom: 18px;
      left: 50%;
      background-image: url("/assets/img/common/icon_arrow-bottom-white.svg");
      transform: translateX(-50%); }
      @media (min-width: 768px) {
        .c-btn.-anchor::after {
          width: 16px;
          height: 10px;
          bottom: 22px; } }

.c-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0; }
  @media (max-width: 767.98px) {
    .c-table tbody {
      display: block; } }
  @media (max-width: 767.98px) {
    .c-table {
      display: block;
      border: solid 1px #b3b3b3;
      border-bottom: none; } }
  @media (max-width: 767.98px) {
    .c-table__tr {
      display: block; } }
  @media (max-width: 767.98px) {
    .c-table__th, .c-table__td {
      display: block;
      border-bottom: solid 1px #b3b3b3;
      padding: 7px 12px;
      word-break: break-word; } }
  @media (min-width: 768px) {
    .c-table__th, .c-table__td {
      border: solid 1px #b3b3b3;
      padding: 15px 29px; } }
  .c-table__th {
    text-align: center;
    background-color: #f2f2f2; }
    @media (max-width: 767.98px) {
      .c-table__th {
        font-size: 1.5rem; } }
    @media (min-width: 768px) {
      .c-table__th {
        width: 236px; } }

.p-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0; }
  .p-table__th, .p-table__td {
    border: solid 1px #b3b3b3;
    padding: 15px 29px;
    word-break: break-all; }
    @media (max-width: 767.98px) {
      .p-table__th, .p-table__td {
        font-size: 1.5rem;
        padding: 7px 22px;
        word-break: break-all; } }
  .p-table__th {
    text-align: center;
    background-color: #f2f2f2; }

.c-breadcrumb {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  max-width: 1230px;
  padding: 0 17px;
  margin: 0 auto; }
  @media screen and (max-width: 767.9px) {
    .c-breadcrumb {
      flex-wrap: wrap; } }
  .c-breadcrumb__item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 15px; }
    .c-breadcrumb__item:not(:last-of-type)::after {
      content: ">";
      padding-left: 15px; }
    @media screen and (max-width: 767.9px) {
      .c-breadcrumb__item {
        white-space: initial; } }
  .c-breadcrumb__link {
    padding: 3px 0; }

.c-list.-desc {
  list-style-type: disc;
  padding-left: 1.5em; }

.c-list__item {
  line-height: 2.1; }

/* =============================================
* Utilities
* ============================================= */
@media screen and (min-width: 768px) {
  .v-sp,
  .h-pc {
    display: none !important; } }

@media screen and (max-width: 767.9px) {
  .v-pc,
  .h-sp {
    display: none !important; } }

/* ---- display ---- */
html body .db {
  display: block; }

html body .dib {
  display: inline-block; }

html body .dn {
  display: none; }

/* ---- テキスト位置揃え ---- */
html body .tac {
  text-align: center; }

html body .tal {
  text-align: left; }

html body .tar {
  text-align: right; }

html body .fwb {
  font-weight: bold; }

html body .fwn {
  font-weight: normal; }

/* ---- テキスト装飾 ---- */
html body .tdn {
  text-decoration: none; }

html body .tdu {
  text-decoration: underline; }

/* ---- テキストサイズ ---- */
html body .fs10 {
  font-size: 1rem; }

html body .fs11 {
  font-size: 1.1rem; }

html body .fs12 {
  font-size: 1.2rem; }

html body .fs13 {
  font-size: 1.3rem; }

html body .fs14 {
  font-size: 1.4rem; }

html body .fs15 {
  font-size: 1.5rem; }

html body .fs16 {
  font-size: 1.6rem; }

html body .fs17 {
  font-size: 1.7rem; }

html body .fs18 {
  font-size: 1.8rem; }

html body .fs19 {
  font-size: 1.9rem; }

html body .fs20 {
  font-size: 2rem; }

html body .fs21 {
  font-size: 2.1rem; }

html body .fs22 {
  font-size: 2.2rem; }

html body .fs23 {
  font-size: 2.3rem; }

html body .fs24 {
  font-size: 2.4rem; }

html body .fs25 {
  font-size: 2.5rem; }

html body .fs26 {
  font-size: 2.6rem; }

html body .fs27 {
  font-size: 2.7rem; }

html body .fs28 {
  font-size: 2.8rem; }

html body .fs29 {
  font-size: 2.9rem; }

html body .fs30 {
  font-size: 3rem; }

html body .fs31 {
  font-size: 3.1rem; }

html body .fs32 {
  font-size: 3.2rem; }

html body .fs33 {
  font-size: 3.3rem; }

html body .fs34 {
  font-size: 3.4rem; }

html body .fs35 {
  font-size: 3.5rem; }

html body .fs36 {
  font-size: 3.6rem; }

html body .fs37 {
  font-size: 3.7rem; }

html body .fs38 {
  font-size: 3.8rem; }

html body .fs39 {
  font-size: 3.9rem; }

html body .fs40 {
  font-size: 4rem; }

html body .fs41 {
  font-size: 4.1rem; }

html body .fs42 {
  font-size: 4.2rem; }

html body .fs43 {
  font-size: 4.3rem; }

html body .fs44 {
  font-size: 4.4rem; }

html body .fs45 {
  font-size: 4.5rem; }

html body .fs46 {
  font-size: 4.6rem; }

html body .fs55 {
  font-size: 5.5rem; }

@media screen and (min-width: 768px) {
  html body .pcfs10 {
    font-size: 1rem; }
  html body .pcfs11 {
    font-size: 1.1rem; }
  html body .pcfs12 {
    font-size: 1.2rem; }
  html body .pcfs13 {
    font-size: 1.3rem; }
  html body .pcfs14 {
    font-size: 1.4rem; }
  html body .pcfs15 {
    font-size: 1.5rem; }
  html body .pcfs16 {
    font-size: 1.6rem; }
  html body .pcfs17 {
    font-size: 1.7rem; }
  html body .pcfs18 {
    font-size: 1.8rem; }
  html body .pcfs19 {
    font-size: 1.9rem; }
  html body .pcfs20 {
    font-size: 2rem; }
  html body .pcfs21 {
    font-size: 2.1rem; }
  html body .pcfs22 {
    font-size: 2.2rem; }
  html body .pcfs23 {
    font-size: 2.3rem; }
  html body .pcfs24 {
    font-size: 2.4rem; }
  html body .pcfs25 {
    font-size: 2.5rem; }
  html body .pcfs26 {
    font-size: 2.6rem; }
  html body .pcfs27 {
    font-size: 2.7rem; }
  html body .pcfs28 {
    font-size: 2.8rem; }
  html body .pcfs29 {
    font-size: 2.9rem; }
  html body .pcfs30 {
    font-size: 3rem; }
  html body .pcfs31 {
    font-size: 3.1rem; }
  html body .pcfs32 {
    font-size: 3.2rem; }
  html body .pcfs33 {
    font-size: 3.3rem; }
  html body .pcfs34 {
    font-size: 3.4rem; }
  html body .pcfs35 {
    font-size: 3.5rem; }
  html body .pcfs36 {
    font-size: 3.6rem; }
  html body .pcfs37 {
    font-size: 3.7rem; }
  html body .pcfs38 {
    font-size: 3.8rem; }
  html body .pcfs39 {
    font-size: 3.9rem; }
  html body .pcfs40 {
    font-size: 4rem; }
  html body .pcfs41 {
    font-size: 4.1rem; }
  html body .pcfs42 {
    font-size: 4.2rem; }
  html body .pcfs43 {
    font-size: 4.3rem; }
  html body .pcfs44 {
    font-size: 4.4rem; }
  html body .pcfs45 {
    font-size: 4.5rem; }
  html body .pcfs46 {
    font-size: 4.6rem; } }

@media screen and (max-width: 767.9px) {
  html body .spfs10 {
    font-size: 1rem; }
  html body .spfs11 {
    font-size: 1.1rem; }
  html body .spfs12 {
    font-size: 1.2rem; }
  html body .spfs13 {
    font-size: 1.3rem; }
  html body .spfs14 {
    font-size: 1.4rem; }
  html body .spfs15 {
    font-size: 1.5rem; }
  html body .spfs16 {
    font-size: 1.6rem; }
  html body .spfs17 {
    font-size: 1.7rem; }
  html body .spfs18 {
    font-size: 1.8rem; }
  html body .spfs19 {
    font-size: 1.9rem; }
  html body .spfs20 {
    font-size: 2rem; }
  html body .spfs21 {
    font-size: 2.1rem; }
  html body .spfs22 {
    font-size: 2.2rem; }
  html body .spfs23 {
    font-size: 2.3rem; }
  html body .spfs24 {
    font-size: 2.4rem; }
  html body .spfs25 {
    font-size: 2.5rem; }
  html body .spfs26 {
    font-size: 2.6rem; }
  html body .spfs27 {
    font-size: 2.7rem; }
  html body .spfs28 {
    font-size: 2.8rem; }
  html body .spfs29 {
    font-size: 2.9rem; }
  html body .spfs30 {
    font-size: 3rem; }
  html body .spfs31 {
    font-size: 3.1rem; }
  html body .spfs32 {
    font-size: 3.2rem; }
  html body .spfs33 {
    font-size: 3.3rem; }
  html body .spfs34 {
    font-size: 3.4rem; }
  html body .spfs35 {
    font-size: 3.5rem; }
  html body .spfs36 {
    font-size: 3.6rem; }
  html body .spfs37 {
    font-size: 3.7rem; }
  html body .spfs38 {
    font-size: 3.8rem; }
  html body .spfs39 {
    font-size: 3.9rem; }
  html body .spfs40 {
    font-size: 4rem; }
  html body .spfs41 {
    font-size: 4.1rem; }
  html body .spfs42 {
    font-size: 4.2rem; }
  html body .spfs43 {
    font-size: 4.3rem; }
  html body .spfs44 {
    font-size: 4.4rem; }
  html body .spfs45 {
    font-size: 4.5rem; }
  html body .spfs46 {
    font-size: 4.6rem; } }

html body .mt0 {
  margin-top: 0; }

html body .mb0 {
  margin-bottom: 0; }

html body .ml0 {
  margin-left: 0; }

html body .mr0 {
  margin-right: 0; }

html body .pt0 {
  padding-top: 0; }

html body .pb0 {
  padding-bottom: 0; }

html body .pl0 {
  padding-left: 0; }

html body .pr0 {
  padding-right: 0; }

html body .mt5 {
  margin-top: 5px; }

html body .mb5 {
  margin-bottom: 5px; }

html body .ml5 {
  margin-left: 5px; }

html body .mr5 {
  margin-right: 5px; }

html body .pt5 {
  padding-top: 5px; }

html body .pb5 {
  padding-bottom: 5px; }

html body .pl5 {
  padding-left: 5px; }

html body .pr5 {
  padding-right: 5px; }

html body .mt10 {
  margin-top: 10px; }

html body .mb10 {
  margin-bottom: 10px; }

html body .ml10 {
  margin-left: 10px; }

html body .mr10 {
  margin-right: 10px; }

html body .pt10 {
  padding-top: 10px; }

html body .pb10 {
  padding-bottom: 10px; }

html body .pl10 {
  padding-left: 10px; }

html body .pr10 {
  padding-right: 10px; }

html body .mt15 {
  margin-top: 15px; }

html body .mb15 {
  margin-bottom: 15px; }

html body .ml15 {
  margin-left: 15px; }

html body .mr15 {
  margin-right: 15px; }

html body .pt15 {
  padding-top: 15px; }

html body .pb15 {
  padding-bottom: 15px; }

html body .pl15 {
  padding-left: 15px; }

html body .pr15 {
  padding-right: 15px; }

html body .mt20 {
  margin-top: 20px; }

html body .mb20 {
  margin-bottom: 20px; }

html body .ml20 {
  margin-left: 20px; }

html body .mr20 {
  margin-right: 20px; }

html body .pt20 {
  padding-top: 20px; }

html body .pb20 {
  padding-bottom: 20px; }

html body .pl20 {
  padding-left: 20px; }

html body .pr20 {
  padding-right: 20px; }

html body .mt25 {
  margin-top: 25px; }

html body .mb25 {
  margin-bottom: 25px; }

html body .ml25 {
  margin-left: 25px; }

html body .mr25 {
  margin-right: 25px; }

html body .pt25 {
  padding-top: 25px; }

html body .pb25 {
  padding-bottom: 25px; }

html body .pl25 {
  padding-left: 25px; }

html body .pr25 {
  padding-right: 25px; }

html body .mt30 {
  margin-top: 30px; }

html body .mb30 {
  margin-bottom: 30px; }

html body .ml30 {
  margin-left: 30px; }

html body .mr30 {
  margin-right: 30px; }

html body .pt30 {
  padding-top: 30px; }

html body .pb30 {
  padding-bottom: 30px; }

html body .pl30 {
  padding-left: 30px; }

html body .pr30 {
  padding-right: 30px; }

html body .mt35 {
  margin-top: 35px; }

html body .mb35 {
  margin-bottom: 35px; }

html body .ml35 {
  margin-left: 35px; }

html body .mr35 {
  margin-right: 35px; }

html body .pt35 {
  padding-top: 35px; }

html body .pb35 {
  padding-bottom: 35px; }

html body .pl35 {
  padding-left: 35px; }

html body .pr35 {
  padding-right: 35px; }

html body .mt40 {
  margin-top: 40px; }

html body .mb40 {
  margin-bottom: 40px; }

html body .ml40 {
  margin-left: 40px; }

html body .mr40 {
  margin-right: 40px; }

html body .pt40 {
  padding-top: 40px; }

html body .pb40 {
  padding-bottom: 40px; }

html body .pl40 {
  padding-left: 40px; }

html body .pr40 {
  padding-right: 40px; }

html body .mt45 {
  margin-top: 45px; }

html body .mb45 {
  margin-bottom: 45px; }

html body .ml45 {
  margin-left: 45px; }

html body .mr45 {
  margin-right: 45px; }

html body .pt45 {
  padding-top: 45px; }

html body .pb45 {
  padding-bottom: 45px; }

html body .pl45 {
  padding-left: 45px; }

html body .pr45 {
  padding-right: 45px; }

html body .mt50 {
  margin-top: 50px; }

html body .mb50 {
  margin-bottom: 50px; }

html body .ml50 {
  margin-left: 50px; }

html body .mr50 {
  margin-right: 50px; }

html body .pt50 {
  padding-top: 50px; }

html body .pb50 {
  padding-bottom: 50px; }

html body .pl50 {
  padding-left: 50px; }

html body .pr50 {
  padding-right: 50px; }

html body .mt55 {
  margin-top: 55px; }

html body .mb55 {
  margin-bottom: 55px; }

html body .ml55 {
  margin-left: 55px; }

html body .mr55 {
  margin-right: 55px; }

html body .pt55 {
  padding-top: 55px; }

html body .pb55 {
  padding-bottom: 55px; }

html body .pl55 {
  padding-left: 55px; }

html body .pr55 {
  padding-right: 55px; }

html body .mt60 {
  margin-top: 60px; }

html body .mb60 {
  margin-bottom: 60px; }

html body .ml60 {
  margin-left: 60px; }

html body .mr60 {
  margin-right: 60px; }

html body .pt60 {
  padding-top: 60px; }

html body .pb60 {
  padding-bottom: 60px; }

html body .pl60 {
  padding-left: 60px; }

html body .pr60 {
  padding-right: 60px; }

html body .mt65 {
  margin-top: 65px; }

html body .mb65 {
  margin-bottom: 65px; }

html body .ml65 {
  margin-left: 65px; }

html body .mr65 {
  margin-right: 65px; }

html body .pt65 {
  padding-top: 65px; }

html body .pb65 {
  padding-bottom: 65px; }

html body .pl65 {
  padding-left: 65px; }

html body .pr65 {
  padding-right: 65px; }

html body .mt70 {
  margin-top: 70px; }

html body .mb70 {
  margin-bottom: 70px; }

html body .ml70 {
  margin-left: 70px; }

html body .mr70 {
  margin-right: 70px; }

html body .pt70 {
  padding-top: 70px; }

html body .pb70 {
  padding-bottom: 70px; }

html body .pl70 {
  padding-left: 70px; }

html body .pr70 {
  padding-right: 70px; }

html body .mt75 {
  margin-top: 75px; }

html body .mb75 {
  margin-bottom: 75px; }

html body .ml75 {
  margin-left: 75px; }

html body .mr75 {
  margin-right: 75px; }

html body .pt75 {
  padding-top: 75px; }

html body .pb75 {
  padding-bottom: 75px; }

html body .pl75 {
  padding-left: 75px; }

html body .pr75 {
  padding-right: 75px; }

html body .mt80 {
  margin-top: 80px; }

html body .mb80 {
  margin-bottom: 80px; }

html body .ml80 {
  margin-left: 80px; }

html body .mr80 {
  margin-right: 80px; }

html body .pt80 {
  padding-top: 80px; }

html body .pb80 {
  padding-bottom: 80px; }

html body .pl80 {
  padding-left: 80px; }

html body .pr80 {
  padding-right: 80px; }

html body .mt85 {
  margin-top: 85px; }

html body .mb85 {
  margin-bottom: 85px; }

html body .ml85 {
  margin-left: 85px; }

html body .mr85 {
  margin-right: 85px; }

html body .pt85 {
  padding-top: 85px; }

html body .pb85 {
  padding-bottom: 85px; }

html body .pl85 {
  padding-left: 85px; }

html body .pr85 {
  padding-right: 85px; }

html body .mt90 {
  margin-top: 90px; }

html body .mb90 {
  margin-bottom: 90px; }

html body .ml90 {
  margin-left: 90px; }

html body .mr90 {
  margin-right: 90px; }

html body .pt90 {
  padding-top: 90px; }

html body .pb90 {
  padding-bottom: 90px; }

html body .pl90 {
  padding-left: 90px; }

html body .pr90 {
  padding-right: 90px; }

html body .mt95 {
  margin-top: 95px; }

html body .mb95 {
  margin-bottom: 95px; }

html body .ml95 {
  margin-left: 95px; }

html body .mr95 {
  margin-right: 95px; }

html body .pt95 {
  padding-top: 95px; }

html body .pb95 {
  padding-bottom: 95px; }

html body .pl95 {
  padding-left: 95px; }

html body .pr95 {
  padding-right: 95px; }

html body .mt100 {
  margin-top: 100px; }

html body .mb100 {
  margin-bottom: 100px; }

html body .ml100 {
  margin-left: 100px; }

html body .mr100 {
  margin-right: 100px; }

html body .pt100 {
  padding-top: 100px; }

html body .pb100 {
  padding-bottom: 100px; }

html body .pl100 {
  padding-left: 100px; }

html body .pr100 {
  padding-right: 100px; }

@media screen and (min-width: 768px) {
  html body .pcmt0 {
    margin-top: 0 !important; }
  html body .pcmb0 {
    margin-bottom: 0 !important; }
  html body .pcml0 {
    margin-left: 0 !important; }
  html body .pcmr0 {
    margin-right: 0 !important; }
  html body .pcpt0 {
    padding-top: 0 !important; }
  html body .pcpb0 {
    padding-bottom: 0 !important; }
  html body .pcpl0 {
    padding-left: 0 !important; }
  html body .pcpr0 {
    padding-right: 0 !important; }
  html body .pcmt5 {
    margin-top: 5px !important; }
  html body .pcmb5 {
    margin-bottom: 5px !important; }
  html body .pcml5 {
    margin-left: 5px !important; }
  html body .pcmr5 {
    margin-right: 5px !important; }
  html body .pcpt5 {
    padding-top: 5px !important; }
  html body .pcpb5 {
    padding-bottom: 5px !important; }
  html body .pcpl5 {
    padding-left: 5px !important; }
  html body .pcpr5 {
    padding-right: 5px !important; }
  html body .pcmt10 {
    margin-top: 10px !important; }
  html body .pcmb10 {
    margin-bottom: 10px !important; }
  html body .pcml10 {
    margin-left: 10px !important; }
  html body .pcmr10 {
    margin-right: 10px !important; }
  html body .pcpt10 {
    padding-top: 10px !important; }
  html body .pcpb10 {
    padding-bottom: 10px !important; }
  html body .pcpl10 {
    padding-left: 10px !important; }
  html body .pcpr10 {
    padding-right: 10px !important; }
  html body .pcmt15 {
    margin-top: 15px !important; }
  html body .pcmb15 {
    margin-bottom: 15px !important; }
  html body .pcml15 {
    margin-left: 15px !important; }
  html body .pcmr15 {
    margin-right: 15px !important; }
  html body .pcpt15 {
    padding-top: 15px !important; }
  html body .pcpb15 {
    padding-bottom: 15px !important; }
  html body .pcpl15 {
    padding-left: 15px !important; }
  html body .pcpr15 {
    padding-right: 15px !important; }
  html body .pcmt20 {
    margin-top: 20px !important; }
  html body .pcmb20 {
    margin-bottom: 20px !important; }
  html body .pcml20 {
    margin-left: 20px !important; }
  html body .pcmr20 {
    margin-right: 20px !important; }
  html body .pcpt20 {
    padding-top: 20px !important; }
  html body .pcpb20 {
    padding-bottom: 20px !important; }
  html body .pcpl20 {
    padding-left: 20px !important; }
  html body .pcpr20 {
    padding-right: 20px !important; }
  html body .pcmt25 {
    margin-top: 25px !important; }
  html body .pcmb25 {
    margin-bottom: 25px !important; }
  html body .pcml25 {
    margin-left: 25px !important; }
  html body .pcmr25 {
    margin-right: 25px !important; }
  html body .pcpt25 {
    padding-top: 25px !important; }
  html body .pcpb25 {
    padding-bottom: 25px !important; }
  html body .pcpl25 {
    padding-left: 25px !important; }
  html body .pcpr25 {
    padding-right: 25px !important; }
  html body .pcmt30 {
    margin-top: 30px !important; }
  html body .pcmb30 {
    margin-bottom: 30px !important; }
  html body .pcml30 {
    margin-left: 30px !important; }
  html body .pcmr30 {
    margin-right: 30px !important; }
  html body .pcpt30 {
    padding-top: 30px !important; }
  html body .pcpb30 {
    padding-bottom: 30px !important; }
  html body .pcpl30 {
    padding-left: 30px !important; }
  html body .pcpr30 {
    padding-right: 30px !important; }
  html body .pcmt35 {
    margin-top: 35px !important; }
  html body .pcmb35 {
    margin-bottom: 35px !important; }
  html body .pcml35 {
    margin-left: 35px !important; }
  html body .pcmr35 {
    margin-right: 35px !important; }
  html body .pcpt35 {
    padding-top: 35px !important; }
  html body .pcpb35 {
    padding-bottom: 35px !important; }
  html body .pcpl35 {
    padding-left: 35px !important; }
  html body .pcpr35 {
    padding-right: 35px !important; }
  html body .pcmt40 {
    margin-top: 40px !important; }
  html body .pcmb40 {
    margin-bottom: 40px !important; }
  html body .pcml40 {
    margin-left: 40px !important; }
  html body .pcmr40 {
    margin-right: 40px !important; }
  html body .pcpt40 {
    padding-top: 40px !important; }
  html body .pcpb40 {
    padding-bottom: 40px !important; }
  html body .pcpl40 {
    padding-left: 40px !important; }
  html body .pcpr40 {
    padding-right: 40px !important; }
  html body .pcmt45 {
    margin-top: 45px !important; }
  html body .pcmb45 {
    margin-bottom: 45px !important; }
  html body .pcml45 {
    margin-left: 45px !important; }
  html body .pcmr45 {
    margin-right: 45px !important; }
  html body .pcpt45 {
    padding-top: 45px !important; }
  html body .pcpb45 {
    padding-bottom: 45px !important; }
  html body .pcpl45 {
    padding-left: 45px !important; }
  html body .pcpr45 {
    padding-right: 45px !important; }
  html body .pcmt50 {
    margin-top: 50px !important; }
  html body .pcmb50 {
    margin-bottom: 50px !important; }
  html body .pcml50 {
    margin-left: 50px !important; }
  html body .pcmr50 {
    margin-right: 50px !important; }
  html body .pcpt50 {
    padding-top: 50px !important; }
  html body .pcpb50 {
    padding-bottom: 50px !important; }
  html body .pcpl50 {
    padding-left: 50px !important; }
  html body .pcpr50 {
    padding-right: 50px !important; }
  html body .pcmt55 {
    margin-top: 55px !important; }
  html body .pcmb55 {
    margin-bottom: 55px !important; }
  html body .pcml55 {
    margin-left: 55px !important; }
  html body .pcmr55 {
    margin-right: 55px !important; }
  html body .pcpt55 {
    padding-top: 55px !important; }
  html body .pcpb55 {
    padding-bottom: 55px !important; }
  html body .pcpl55 {
    padding-left: 55px !important; }
  html body .pcpr55 {
    padding-right: 55px !important; }
  html body .pcmt60 {
    margin-top: 60px !important; }
  html body .pcmb60 {
    margin-bottom: 60px !important; }
  html body .pcml60 {
    margin-left: 60px !important; }
  html body .pcmr60 {
    margin-right: 60px !important; }
  html body .pcpt60 {
    padding-top: 60px !important; }
  html body .pcpb60 {
    padding-bottom: 60px !important; }
  html body .pcpl60 {
    padding-left: 60px !important; }
  html body .pcpr60 {
    padding-right: 60px !important; }
  html body .pcmt65 {
    margin-top: 65px !important; }
  html body .pcmb65 {
    margin-bottom: 65px !important; }
  html body .pcml65 {
    margin-left: 65px !important; }
  html body .pcmr65 {
    margin-right: 65px !important; }
  html body .pcpt65 {
    padding-top: 65px !important; }
  html body .pcpb65 {
    padding-bottom: 65px !important; }
  html body .pcpl65 {
    padding-left: 65px !important; }
  html body .pcpr65 {
    padding-right: 65px !important; }
  html body .pcmt70 {
    margin-top: 70px !important; }
  html body .pcmb70 {
    margin-bottom: 70px !important; }
  html body .pcml70 {
    margin-left: 70px !important; }
  html body .pcmr70 {
    margin-right: 70px !important; }
  html body .pcpt70 {
    padding-top: 70px !important; }
  html body .pcpb70 {
    padding-bottom: 70px !important; }
  html body .pcpl70 {
    padding-left: 70px !important; }
  html body .pcpr70 {
    padding-right: 70px !important; }
  html body .pcmt75 {
    margin-top: 75px !important; }
  html body .pcmb75 {
    margin-bottom: 75px !important; }
  html body .pcml75 {
    margin-left: 75px !important; }
  html body .pcmr75 {
    margin-right: 75px !important; }
  html body .pcpt75 {
    padding-top: 75px !important; }
  html body .pcpb75 {
    padding-bottom: 75px !important; }
  html body .pcpl75 {
    padding-left: 75px !important; }
  html body .pcpr75 {
    padding-right: 75px !important; }
  html body .pcmt80 {
    margin-top: 80px !important; }
  html body .pcmb80 {
    margin-bottom: 80px !important; }
  html body .pcml80 {
    margin-left: 80px !important; }
  html body .pcmr80 {
    margin-right: 80px !important; }
  html body .pcpt80 {
    padding-top: 80px !important; }
  html body .pcpb80 {
    padding-bottom: 80px !important; }
  html body .pcpl80 {
    padding-left: 80px !important; }
  html body .pcpr80 {
    padding-right: 80px !important; }
  html body .pcmt85 {
    margin-top: 85px !important; }
  html body .pcmb85 {
    margin-bottom: 85px !important; }
  html body .pcml85 {
    margin-left: 85px !important; }
  html body .pcmr85 {
    margin-right: 85px !important; }
  html body .pcpt85 {
    padding-top: 85px !important; }
  html body .pcpb85 {
    padding-bottom: 85px !important; }
  html body .pcpl85 {
    padding-left: 85px !important; }
  html body .pcpr85 {
    padding-right: 85px !important; }
  html body .pcmt90 {
    margin-top: 90px !important; }
  html body .pcmb90 {
    margin-bottom: 90px !important; }
  html body .pcml90 {
    margin-left: 90px !important; }
  html body .pcmr90 {
    margin-right: 90px !important; }
  html body .pcpt90 {
    padding-top: 90px !important; }
  html body .pcpb90 {
    padding-bottom: 90px !important; }
  html body .pcpl90 {
    padding-left: 90px !important; }
  html body .pcpr90 {
    padding-right: 90px !important; }
  html body .pcmt95 {
    margin-top: 95px !important; }
  html body .pcmb95 {
    margin-bottom: 95px !important; }
  html body .pcml95 {
    margin-left: 95px !important; }
  html body .pcmr95 {
    margin-right: 95px !important; }
  html body .pcpt95 {
    padding-top: 95px !important; }
  html body .pcpb95 {
    padding-bottom: 95px !important; }
  html body .pcpl95 {
    padding-left: 95px !important; }
  html body .pcpr95 {
    padding-right: 95px !important; }
  html body .pcmt100 {
    margin-top: 100px !important; }
  html body .pcmb100 {
    margin-bottom: 100px !important; }
  html body .pcml100 {
    margin-left: 100px !important; }
  html body .pcmr100 {
    margin-right: 100px !important; }
  html body .pcpt100 {
    padding-top: 100px !important; }
  html body .pcpb100 {
    padding-bottom: 100px !important; }
  html body .pcpl100 {
    padding-left: 100px !important; }
  html body .pcpr100 {
    padding-right: 100px !important; } }

@media screen and (max-width: 767.9px) {
  html body .spmt0 {
    margin-top: 0; }
  html body .spmb0 {
    margin-bottom: 0; }
  html body .spml0 {
    margin-left: 0; }
  html body .spmr0 {
    margin-right: 0; }
  html body .sppt0 {
    padding-top: 0; }
  html body .sppb0 {
    padding-bottom: 0; }
  html body .sppl0 {
    padding-left: 0; }
  html body .sppr0 {
    padding-right: 0; }
  html body .spmt5 {
    margin-top: 5px; }
  html body .spmb5 {
    margin-bottom: 5px; }
  html body .spml5 {
    margin-left: 5px; }
  html body .spmr5 {
    margin-right: 5px; }
  html body .sppt5 {
    padding-top: 5px; }
  html body .sppb5 {
    padding-bottom: 5px; }
  html body .sppl5 {
    padding-left: 5px; }
  html body .sppr5 {
    padding-right: 5px; }
  html body .spmt10 {
    margin-top: 10px; }
  html body .spmb10 {
    margin-bottom: 10px; }
  html body .spml10 {
    margin-left: 10px; }
  html body .spmr10 {
    margin-right: 10px; }
  html body .sppt10 {
    padding-top: 10px; }
  html body .sppb10 {
    padding-bottom: 10px; }
  html body .sppl10 {
    padding-left: 10px; }
  html body .sppr10 {
    padding-right: 10px; }
  html body .spmt15 {
    margin-top: 15px; }
  html body .spmb15 {
    margin-bottom: 15px; }
  html body .spml15 {
    margin-left: 15px; }
  html body .spmr15 {
    margin-right: 15px; }
  html body .sppt15 {
    padding-top: 15px; }
  html body .sppb15 {
    padding-bottom: 15px; }
  html body .sppl15 {
    padding-left: 15px; }
  html body .sppr15 {
    padding-right: 15px; }
  html body .spmt20 {
    margin-top: 20px; }
  html body .spmb20 {
    margin-bottom: 20px; }
  html body .spml20 {
    margin-left: 20px; }
  html body .spmr20 {
    margin-right: 20px; }
  html body .sppt20 {
    padding-top: 20px; }
  html body .sppb20 {
    padding-bottom: 20px; }
  html body .sppl20 {
    padding-left: 20px; }
  html body .sppr20 {
    padding-right: 20px; }
  html body .spmt25 {
    margin-top: 25px; }
  html body .spmb25 {
    margin-bottom: 25px; }
  html body .spml25 {
    margin-left: 25px; }
  html body .spmr25 {
    margin-right: 25px; }
  html body .sppt25 {
    padding-top: 25px; }
  html body .sppb25 {
    padding-bottom: 25px; }
  html body .sppl25 {
    padding-left: 25px; }
  html body .sppr25 {
    padding-right: 25px; }
  html body .spmt30 {
    margin-top: 30px; }
  html body .spmb30 {
    margin-bottom: 30px; }
  html body .spml30 {
    margin-left: 30px; }
  html body .spmr30 {
    margin-right: 30px; }
  html body .sppt30 {
    padding-top: 30px; }
  html body .sppb30 {
    padding-bottom: 30px; }
  html body .sppl30 {
    padding-left: 30px; }
  html body .sppr30 {
    padding-right: 30px; }
  html body .spmt35 {
    margin-top: 35px; }
  html body .spmb35 {
    margin-bottom: 35px; }
  html body .spml35 {
    margin-left: 35px; }
  html body .spmr35 {
    margin-right: 35px; }
  html body .sppt35 {
    padding-top: 35px; }
  html body .sppb35 {
    padding-bottom: 35px; }
  html body .sppl35 {
    padding-left: 35px; }
  html body .sppr35 {
    padding-right: 35px; }
  html body .spmt40 {
    margin-top: 40px; }
  html body .spmb40 {
    margin-bottom: 40px; }
  html body .spml40 {
    margin-left: 40px; }
  html body .spmr40 {
    margin-right: 40px; }
  html body .sppt40 {
    padding-top: 40px; }
  html body .sppb40 {
    padding-bottom: 40px; }
  html body .sppl40 {
    padding-left: 40px; }
  html body .sppr40 {
    padding-right: 40px; }
  html body .spmt45 {
    margin-top: 45px; }
  html body .spmb45 {
    margin-bottom: 45px; }
  html body .spml45 {
    margin-left: 45px; }
  html body .spmr45 {
    margin-right: 45px; }
  html body .sppt45 {
    padding-top: 45px; }
  html body .sppb45 {
    padding-bottom: 45px; }
  html body .sppl45 {
    padding-left: 45px; }
  html body .sppr45 {
    padding-right: 45px; }
  html body .spmt50 {
    margin-top: 50px; }
  html body .spmb50 {
    margin-bottom: 50px; }
  html body .spml50 {
    margin-left: 50px; }
  html body .spmr50 {
    margin-right: 50px; }
  html body .sppt50 {
    padding-top: 50px; }
  html body .sppb50 {
    padding-bottom: 50px; }
  html body .sppl50 {
    padding-left: 50px; }
  html body .sppr50 {
    padding-right: 50px; }
  html body .spmt55 {
    margin-top: 55px; }
  html body .spmb55 {
    margin-bottom: 55px; }
  html body .spml55 {
    margin-left: 55px; }
  html body .spmr55 {
    margin-right: 55px; }
  html body .sppt55 {
    padding-top: 55px; }
  html body .sppb55 {
    padding-bottom: 55px; }
  html body .sppl55 {
    padding-left: 55px; }
  html body .sppr55 {
    padding-right: 55px; }
  html body .spmt60 {
    margin-top: 60px; }
  html body .spmb60 {
    margin-bottom: 60px; }
  html body .spml60 {
    margin-left: 60px; }
  html body .spmr60 {
    margin-right: 60px; }
  html body .sppt60 {
    padding-top: 60px; }
  html body .sppb60 {
    padding-bottom: 60px; }
  html body .sppl60 {
    padding-left: 60px; }
  html body .sppr60 {
    padding-right: 60px; }
  html body .spmt65 {
    margin-top: 65px; }
  html body .spmb65 {
    margin-bottom: 65px; }
  html body .spml65 {
    margin-left: 65px; }
  html body .spmr65 {
    margin-right: 65px; }
  html body .sppt65 {
    padding-top: 65px; }
  html body .sppb65 {
    padding-bottom: 65px; }
  html body .sppl65 {
    padding-left: 65px; }
  html body .sppr65 {
    padding-right: 65px; }
  html body .spmt70 {
    margin-top: 70px; }
  html body .spmb70 {
    margin-bottom: 70px; }
  html body .spml70 {
    margin-left: 70px; }
  html body .spmr70 {
    margin-right: 70px; }
  html body .sppt70 {
    padding-top: 70px; }
  html body .sppb70 {
    padding-bottom: 70px; }
  html body .sppl70 {
    padding-left: 70px; }
  html body .sppr70 {
    padding-right: 70px; }
  html body .spmt75 {
    margin-top: 75px; }
  html body .spmb75 {
    margin-bottom: 75px; }
  html body .spml75 {
    margin-left: 75px; }
  html body .spmr75 {
    margin-right: 75px; }
  html body .sppt75 {
    padding-top: 75px; }
  html body .sppb75 {
    padding-bottom: 75px; }
  html body .sppl75 {
    padding-left: 75px; }
  html body .sppr75 {
    padding-right: 75px; }
  html body .spmt80 {
    margin-top: 80px; }
  html body .spmb80 {
    margin-bottom: 80px; }
  html body .spml80 {
    margin-left: 80px; }
  html body .spmr80 {
    margin-right: 80px; }
  html body .sppt80 {
    padding-top: 80px; }
  html body .sppb80 {
    padding-bottom: 80px; }
  html body .sppl80 {
    padding-left: 80px; }
  html body .sppr80 {
    padding-right: 80px; }
  html body .spmt85 {
    margin-top: 85px; }
  html body .spmb85 {
    margin-bottom: 85px; }
  html body .spml85 {
    margin-left: 85px; }
  html body .spmr85 {
    margin-right: 85px; }
  html body .sppt85 {
    padding-top: 85px; }
  html body .sppb85 {
    padding-bottom: 85px; }
  html body .sppl85 {
    padding-left: 85px; }
  html body .sppr85 {
    padding-right: 85px; }
  html body .spmt90 {
    margin-top: 90px; }
  html body .spmb90 {
    margin-bottom: 90px; }
  html body .spml90 {
    margin-left: 90px; }
  html body .spmr90 {
    margin-right: 90px; }
  html body .sppt90 {
    padding-top: 90px; }
  html body .sppb90 {
    padding-bottom: 90px; }
  html body .sppl90 {
    padding-left: 90px; }
  html body .sppr90 {
    padding-right: 90px; }
  html body .spmt95 {
    margin-top: 95px; }
  html body .spmb95 {
    margin-bottom: 95px; }
  html body .spml95 {
    margin-left: 95px; }
  html body .spmr95 {
    margin-right: 95px; }
  html body .sppt95 {
    padding-top: 95px; }
  html body .sppb95 {
    padding-bottom: 95px; }
  html body .sppl95 {
    padding-left: 95px; }
  html body .sppr95 {
    padding-right: 95px; }
  html body .spmt100 {
    margin-top: 100px; }
  html body .spmb100 {
    margin-bottom: 100px; }
  html body .spml100 {
    margin-left: 100px; }
  html body .spmr100 {
    margin-right: 100px; }
  html body .sppt100 {
    padding-top: 100px; }
  html body .sppb100 {
    padding-bottom: 100px; }
  html body .sppl100 {
    padding-left: 100px; }
  html body .sppr100 {
    padding-right: 100px; } }

/* ---- テキストカラー ---- */
.fc-red {
  color: #f00; }

/* ---- 画像リサイズ ---- */
.resize {
  width: 100%; }

@media screen and (max-width: 767.9px) {
  .resize-sp {
    width: 100%; } }

.lh1-2 {
  line-height: 1.2; }

.p-top__sec {
  width: 100%; }
  @media (min-width: 768px) {
    .p-top__sec {
      display: flex;
      justify-content: center; } }
  .p-top__sec__inner {
    width: 100%;
    max-width: 1230px; }
    @media (min-width: 768px) {
      .p-top__sec__inner {
        padding: 0 20px; } }
  .p-top__sec.-kv {
    position: relative; }
    @media (min-width: 768px) {
      .p-top__sec.-kv {
        min-height: 430px;
        height: calc(100vh - 107px); } }
  .p-top__sec.-vision {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("/assets/img/top/bg_vision-sp.jpg");
    padding: 38px 15px 40px; }
    @media (min-width: 768px) {
      .p-top__sec.-vision {
        height: 100vh;
        min-height: 530px;
        align-items: center;
        background-image: url("/assets/img/top/bg_vision.jpg");
        padding: 0 0 20px; } }
  .p-top__sec-vision__h2 {
    margin-bottom: 59px; }
    @media (min-width: 768px) {
      .p-top__sec-vision__h2 {
        margin-bottom: 35px; } }
  .p-top__sec-vision__h3 {
    font-size: 2.1rem;
    color: white;
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .p-top__sec-vision__h3 {
        font-size: 3.5rem; } }
  .p-top__sec-vision__catch {
    font-size: 1.5rem;
    line-height: 1.6;
    color: white;
    margin-bottom: 23px; }
    @media (min-width: 768px) {
      .p-top__sec-vision__catch {
        font-size: 2.5rem;
        margin-bottom: 35px; } }
  .p-top__sec-vision__msg {
    font-size: 1.3rem;
    color: white;
    margin-bottom: 36px; }
    @media (min-width: 768px) {
      .p-top__sec-vision__msg {
        font-size: 1.5rem;
        width: 580px;
        margin-bottom: 50px; } }
  .p-top__sec.-news {
    padding: 40px 0 80px; }
    @media (min-width: 768px) {
      .p-top__sec.-news {
        padding-top: 128px; } }
    @media (max-width: 767.98px) {
      .p-top__sec.-news .p-top__sec__inner {
        padding: 0 15px; } }
    .p-top__sec.-news__h2 {
      margin-bottom: 44px; }
      @media (min-width: 768px) {
        .p-top__sec.-news__h2 {
          margin-bottom: 85px; } }

.p-top__pagination {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(50% + 50px);
  right: 20px;
  transform: translateY(-50%);
  z-index: 100;
  padding: 0; }
  @media (max-width: 767.98px) {
    .p-top__pagination {
      display: none; } }
  .p-top__pagination__item {
    display: flex;
    margin: 17px 0; }
  .p-top__pagination__link {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #b3b3b3; }
  body[data-current-section='kv'] .p-top__pagination__item:nth-of-type(1) .p-top__pagination__link,
  body[data-current-section='vision'] .p-top__pagination__item:nth-of-type(2) .p-top__pagination__link,
  body[data-current-section='news'] .p-top__pagination__item:nth-of-type(3) .p-top__pagination__link {
    background-color: #fff;
    border: solid 1px #b3b3b3; }
  .p-top__pagination__scroll-btn {
    margin-top: 36px;
    padding-left: 5px; }
    .p-top__pagination__scroll-btn__img {
      width: 9px; }

.p-privacy {
  padding: 40px 0 80px; }
  @media (min-width: 768px) {
    .p-privacy {
      padding: 100px 0 160px; } }
  .p-privacy__anchor-nav {
    margin-bottom: 42px; }
    @media (min-width: 768px) {
      .p-privacy__anchor-nav {
        display: flex;
        justify-content: center;
        margin-bottom: 91px; } }
    @media (max-width: 767.98px) {
      .p-privacy__anchor-nav .c-btn:first-of-type {
        margin-bottom: 16px; } }
    @media (min-width: 768px) {
      .p-privacy__anchor-nav .c-btn:first-of-type {
        margin-right: 42px; } }
  .p-privacy__signature {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 32px; }
    @media (min-width: 768px) {
      .p-privacy__signature {
        margin-bottom: 43px; } }
    .p-privacy__signature__date {
      margin-bottom: 24px; }
      @media (min-width: 768px) {
        .p-privacy__signature__date {
          margin-bottom: 39px; } }
    .p-privacy__signature__company {
      margin-bottom: 1px; }
      @media (min-width: 768px) {
        .p-privacy__signature__company {
          margin-bottom: 6px; } }
  .p-privacy__sec.-privacy {
    margin-bottom: 39px; }
    @media (min-width: 768px) {
      .p-privacy__sec.-privacy {
        margin-bottom: 87px; } }
  .p-privacy__h2 {
    font-size: 2.1rem;
    font-weight: bold;
    margin-bottom: 9px; }
    @media (min-width: 768px) {
      .p-privacy__h2 {
        font-size: 3rem;
        margin-bottom: 29px; } }
  .p-privacy__h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 13px; }
    @media (min-width: 768px) {
      .p-privacy__h3 {
        font-size: 2.5rem;
        margin-bottom: 30px; } }
  .p-privacy__h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .p-privacy__h4 {
        font-size: 1.8rem;
        margin-bottom: 3px; } }
  .p-privacy__paragraph {
    line-height: 1.8;
    margin-bottom: 33px; }
    @media (min-width: 768px) {
      .p-privacy__paragraph {
        line-height: 2.1;
        margin-bottom: 33px; } }
    .p-privacy__paragraph.-description {
      margin-bottom: 24px; }
      @media (min-width: 768px) {
        .p-privacy__paragraph.-description {
          margin-bottom: 36px; } }
  .p-privacy__dt {
    line-height: 1.8;
    font-weight: bold; }
    @media (min-width: 768px) {
      .p-privacy__dt {
        line-height: 2.1;
        font-weight: bold; } }
  .p-privacy__dd {
    line-height: 1.8;
    margin-bottom: 23px; }
    @media (min-width: 768px) {
      .p-privacy__dd {
        line-height: 2.1;
        margin-bottom: 33px; } }
  .p-privacy__annot {
    font-size: 1.3rem;
    line-height: 1.8; }
    @media (min-width: 768px) {
      .p-privacy__annot {
        font-size: 1.4rem;
        line-height: 2.1; } }

.p-privacy__li a {
  color: #007bff; }

.p-table__td a {
  color: #007bff; }

.p-company .l-main__inner {
  padding-top: 33px;
  padding-bottom: 80px; }
  @media (min-width: 768px) {
    .p-company .l-main__inner {
      padding-top: 73px;
      padding-bottom: 160px; } }

.p-company__paragraph {
  line-height: 1.8;
  margin-bottom: 33px; }
  @media (min-width: 768px) {
    .p-company__paragraph {
      line-height: 2.1;
      margin-bottom: 33px; } }

.p-company__sec.-vision {
  margin-bottom: 39px; }
  @media (min-width: 768px) {
    .p-company__sec.-vision {
      margin-bottom: 84px; } }
  .p-company__sec.-vision .c-sec-title {
    margin-bottom: 31px; }
    @media (min-width: 768px) {
      .p-company__sec.-vision .c-sec-title {
        margin-bottom: 41px; } }

.p-company__sec.-message {
  margin-bottom: 37px; }
  @media (min-width: 768px) {
    .p-company__sec.-message {
      margin-bottom: 48px; } }
  .p-company__sec.-message .c-sec-title {
    margin-bottom: 34px; }
    @media (min-width: 768px) {
      .p-company__sec.-message .c-sec-title {
        margin-bottom: 42px; } }

.p-company__sec.-info {
  margin-bottom: 46px; }
  @media (min-width: 768px) {
    .p-company__sec.-info {
      margin-bottom: 48px; } }
  .p-company__sec.-info .c-sec-title {
    margin-bottom: 42px; }
    @media (min-width: 768px) {
      .p-company__sec.-info .c-sec-title {
        margin-bottom: 42px; } }

.p-company__sec.-contact {
  margin-bottom: 48px; }
  @media (min-width: 768px) {
    .p-company__sec.-contact {
      margin-bottom: 48px; } }
  .p-company__sec.-contact .c-sec-title {
    margin-bottom: 42px; }
    @media (min-width: 768px) {
      .p-company__sec.-contact .c-sec-title {
        margin-bottom: 42px; } }

.p-company__msg-photo {
  width: 100%;
  margin-bottom: 23px; }
  @media (max-width: 767.98px) {
    .p-company__msg-photo {
      display: flex;
      align-items: center; } }
  @media (min-width: 768px) {
    .p-company__msg-photo {
      width: 237px;
      min-width: 237px;
      padding-top: 8px;
      margin-right: 57px; } }
  .p-company__msg-photo__img {
    margin-bottom: 6px; }
    @media (max-width: 767.98px) {
      .p-company__msg-photo__img {
        width: 44%;
        margin-right: 26px; } }
    @media (min-width: 768px) {
      .p-company__msg-photo__img {
        margin-bottom: 21px; } }

.p-company__msg-sign {
  display: flex;
  white-space: nowrap; }
  @media (max-width: 767.98px) {
    .p-company__msg-sign {
      flex-direction: column; } }
  @media (min-width: 768px) {
    .p-company__msg-sign {
      align-items: center; } }
  .p-company__msg-sign__pos {
    margin-right: 20px; }
    @media (max-width: 767.98px) {
      .p-company__msg-sign__pos {
        font-size: 1.6rem; } }
  .p-company__msg-sign__name {
    font-size: 2.2rem; }
    @media (max-width: 767.98px) {
      .p-company__msg-sign__name {
        line-height: 1; } }

.p-company__msg-body {
  flex-grow: 1; }

.p-company__officer {
  display: flex;
  flex-wrap: wrap; }
  .p-company__officer__dt {
    width: 138px;
    line-height: 2.1; }
  .p-company__officer__dd {
    width: calc(100% - 138px);
    line-height: 2.1;
    margin-bottom: 0; }

.p-company__title {
  font-size: 2.1rem;
  font-weight: bold;
  margin-bottom: 22px; }
  @media (min-width: 768px) {
    .p-company__title {
      font-size: 3rem;
      margin-bottom: 35px; } }
  .p-company__title.-privacy {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .p-company__title.-privacy {
        font-size: 2rem;
        margin-bottom: 20px; } }

.p-company__btn-group {
  margin-bottom: 50px; }
  .p-company__btn-group .c-btn {
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto; }

.p-company__list.-privacy {
  margin-bottom: 35px; }
  .p-company__list.-privacy dt {
    font-weight: bold; }
  .p-company__list.-privacy dd {
    margin-bottom: 10px; }

.p-product .l-main__inner {
  padding-top: 33px;
  padding-bottom: 80px; }
  @media (min-width: 768px) {
    .p-product .l-main__inner {
      padding-top: 73px;
      padding-bottom: 160px; } }

.p-product__sec.-top {
  margin-bottom: 39px; }
  @media (min-width: 768px) {
    .p-product__sec.-top {
      margin-bottom: 84px; } }
  .p-product__sec.-top .c-sec-title {
    margin-bottom: 31px; }
    @media (min-width: 768px) {
      .p-product__sec.-top .c-sec-title {
        margin-bottom: 41px; } }

.p-product__list {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0; }

.p-product__item {
  width: 100%;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 30px;
  margin-bottom: 25px;
  position: relative; }
  @media (min-width: 768px) {
    .p-product__item {
      width: 32%;
      margin-right: 2%; } }
  .p-product__item:nth-child(3n) {
    margin-right: 0; }

.p-product__ttl4 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center; }

.p-product__image {
  width: 56.6%;
  margin-left: auto;
  margin-right: auto;
  min-height: 0%; }
  .p-product__image.-sd {
    width: 76%; }
  .p-product__image img {
    width: 100%;
    vertical-align: bottom; }

.p-product__btn {
  width: 180px;
  height: 43px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.p-product__link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-decoration: none;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.3s ease-in-out; }
  .p-product__link:hover {
    opacity: 0.4;
    background: #fff; }
  .p-product__link.-main:hover {
    background: none;
    opacity: 1; }

.p-product-under .l-main__inner {
  padding-top: 33px;
  padding-bottom: 80px; }
  @media (min-width: 768px) {
    .p-product-under .l-main__inner {
      padding-top: 73px;
      padding-bottom: 160px; } }

.p-product-under__list {
  padding: 0; }

.p-product-under__item {
  padding-top: 65px;
  padding-bottom: 100px;
  padding-left: 20px;
  border-bottom: 1px solid #999;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative; }
  @media (max-width: 767.98px) {
    .p-product-under__item {
      padding-top: 30px;
      padding-bottom: 15px;
      padding-left: 0; } }

.p-product-under__image {
  width: 23%;
  position: relative;
  height: 235px; }
  .p-product-under__image img {
    position: absolute;
    top: 0;
    left: 0; }
  @media (max-width: 767.98px) {
    .p-product-under__image {
      width: 80%;
      height: auto;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto; }
      .p-product-under__image img {
        position: static; } }

.p-product-under-detail {
  width: 73%;
  margin-left: 4%; }
  @media (max-width: 767.98px) {
    .p-product-under-detail {
      width: 100%;
      margin-left: 0; } }
  .p-product-under-detail__list {
    padding: 0;
    margin: 20px 0 0;
    list-style: none; }
  .p-product-under-detail__item {
    font-size: 1.5rem;
    line-height: 1.75;
    position: relative; }
    @media (max-width: 767.98px) {
      .p-product-under-detail__item {
        font-size: 1.4rem; } }

.p-product-under-lineup__ttl4 {
  font-size: 1.8rem;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  margin-top: 20px;
  margin-bottom: 10px; }
  @media screen and (max-width: 767.9px) {
    .p-product-under-lineup__ttl4 {
      font-size: 1.6rem; } }

.p-product-under-lineup__list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: flex-start;
  align-items: stretch; }
  @media screen and (max-width: 767.9px) {
    .p-product-under-lineup__list {
      justify-content: center; } }

.p-product-under-lineup__item {
  width: 32%;
  border: solid 1px #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  margin-right: 2%;
  position: relative;
  margin-bottom: 10px; }
  .p-product-under-lineup__item:nth-child(3n) {
    margin-right: 0; }
  .p-product-under-lineup__item img {
    max-width: 150px; }

.p-product-under-lineup__ttl5 {
  font-size: 1.5rem;
  font-weight: bold; }

.p-product-under-lineup__link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-decoration: none;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.3s ease-in-out; }
  .p-product-under-lineup__link:hover {
    opacity: 0.4;
    background: #fff; }

.p-product-under-lineup__table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.6rem;
  margin-top: 40px; }
  .p-product-under-lineup__table.__top {
    margin-top: 0; }
  @media (max-width: 767.98px) {
    .p-product-under-lineup__table {
      font-size: 1rem;
      word-break: break-word;
      word-wrap: break-word;
      table-layout: fixed;
      margin-top: 20px; } }
  .p-product-under-lineup__table thead th {
    background: #333;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    text-align: center; }
    @media screen and (max-width: 767.9px) {
      .p-product-under-lineup__table thead th {
        padding: 1px; } }
  .p-product-under-lineup__table th,
  .p-product-under-lineup__table td {
    border: solid 1px #999;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    word-break: break-word; }
    .p-product-under-lineup__table th img,
    .p-product-under-lineup__table td img {
      max-width: 150px; }
  .p-product-under-lineup__table th {
    background: #999;
    color: #fff;
    text-align: left;
    padding-left: 20px; }

.p-product-under-lineup__text {
  font-size: 1.2rem;
  margin-top: 5px; }

.p-product-under-lineup__download {
  color: #008ed2;
  display: flex;
  align-items: center;
  justify-content: center; }
  .p-product-under-lineup__download::before {
    content: url(/assets/img/product/ico_download.svg);
    margin-top: 5px; }
  .p-product-under-lineup__download:hover {
    opacity: 0.65;
    color: #008ed2; }

.p-product-under-ttl4 {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.388; }
  @media (max-width: 767.98px) {
    .p-product-under-ttl4 {
      font-size: 1.7rem; } }
  .p-product-under-ttl4 span {
    display: block;
    font-size: 1.5rem;
    line-height: 1.666;
    font-weight: normal; }
    @media (max-width: 767.98px) {
      .p-product-under-ttl4 span {
        font-size: 1.4rem; } }

.p-product-under-ttl5 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  margin-top: 15px;
  margin-bottom: 10px; }
  @media (max-width: 767.98px) {
    .p-product-under-ttl5 {
      font-size: 1.8rem; } }

.p-product-under-comment {
  font-size: 1.2rem; }

.p-product-under-linkarea {
  position: absolute;
  width: 495px;
  height: 43px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  bottom: 30px;
  right: 30px; }
  @media (max-width: 767.98px) {
    .p-product-under-linkarea {
      position: static;
      width: 100%;
      height: auto; } }

.p-product-under__link {
  display: flex;
  height: 43px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.5rem;
  line-height: 1;
  position: relative; }
  .p-product-under__link::after {
    content: "";
    background: url(/assets/img/common/icon_arrow-right-white.svg) no-repeat center;
    width: 10px;
    height: 13px;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%); }
  .p-product-under__link:hover {
    opacity: 0.65;
    color: #fff; }
  .p-product-under__link.-support {
    background: #92959a;
    width: 300px;
    margin-right: 15px; }
    @media (max-width: 767.98px) {
      .p-product-under__link.-support {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0; } }
  .p-product-under__link.-detail {
    background: #008ed2;
    width: 180px; }
    @media (max-width: 767.98px) {
      .p-product-under__link.-detail {
        width: 100%; } }

.p-product-under__table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.6rem; }
  @media (max-width: 767.98px) {
    .p-product-under__table {
      font-size: 1rem;
      word-break: break-word;
      word-wrap: break-word;
      table-layout: fixed; } }
  .p-product-under__table th,
  .p-product-under__table td {
    border: solid 1px #999;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    word-break: break-word; }
    .p-product-under__table th span,
    .p-product-under__table td span {
      font-size: 12px; }
      @media screen and (max-width: 767.9px) {
        .p-product-under__table th span,
        .p-product-under__table td span {
          font-size: 9px; } }
  .p-product-under__table-ttl th {
    background: #333;
    color: #fff;
    font-weight: bold;
    padding: 10px; }
    @media screen and (max-width: 767.9px) {
      .p-product-under__table-ttl th {
        padding: 1px; } }
    .p-product-under__table-ttl th:first-child {
      width: 11%; }
      @media screen and (max-width: 767.9px) {
        .p-product-under__table-ttl th:first-child {
          width: 23%; } }
    .p-product-under__table-ttl th:nth-child(2) {
      width: 11%; }
      @media screen and (max-width: 767.9px) {
        .p-product-under__table-ttl th:nth-child(2) {
          width: 19%; } }
    .p-product-under__table-ttl th:nth-child(3) {
      width: 78%; }
      @media screen and (max-width: 767.9px) {
        .p-product-under__table-ttl th:nth-child(3) {
          width: 58%; } }
  .p-product-under__table-data th {
    background: #e6e6e6;
    font-weight: bold;
    padding: 10px;
    width: 26%; }
    @media (max-width: 767.98px) {
      .p-product-under__table-data th {
        padding: 1px;
        width: 19.333%;
        vertical-align: top; } }
    .p-product-under__table-data th img {
      width: auto;
      display: block;
      margin-left: auto;
      margin-right: auto; }
      @media (max-width: 767.98px) {
        .p-product-under__table-data th img {
          max-width: 44px; } }
      .p-product-under__table-data th img.v-sp {
        display: none; }
        @media (max-width: 767.98px) {
          .p-product-under__table-data th img.v-sp {
            display: block; } }
      .p-product-under__table-data th img.v-pc {
        display: block; }
        @media (max-width: 767.98px) {
          .p-product-under__table-data th img.v-pc {
            display: none; } }
  .p-product-under__table-data02 th {
    background: #e6e6e6;
    font-weight: bold;
    word-break: break-all; }
  .p-product-under__table-link {
    display: block;
    width: 55.555%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px; }
    @media (max-width: 767.98px) {
      .p-product-under__table-link {
        width: 100%;
        margin-bottom: 5px; } }
    .p-product-under__table-link img {
      width: 100%; }

.p-product-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  flex-wrap: wrap;
  font-weight: bold;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0; }
  @media screen and (max-width: 767.9px) {
    .p-product-tab {
      height: 70px;
      font-size: 14px; } }
  .p-product-tab-btn {
    width: 49.5%;
    height: 100%; }
    .p-product-tab-btn a.tab_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: solid 1px #008ed2;
      transition: 0.5s ease;
      position: relative;
      color: #008ed2; }
      .p-product-tab-btn a.tab_btn:hover {
        background: #008ed2; }
        .p-product-tab-btn a.tab_btn:hover .c-sec-title__main {
          color: #fff; }
      .p-product-tab-btn a.tab_btn.is-active-btn {
        color: #fff;
        background: #008ed2; }
        .p-product-tab-btn a.tab_btn.is-active-btn .c-sec-title__main {
          color: #fff; }
  .p-product-tab .c-sec-title {
    margin-bottom: 0 !important;
    align-items: center; }
    .p-product-tab .c-sec-title__main {
      margin-right: 0;
      transform: translateY(0); }
      @media screen and (max-width: 767.9px) {
        .p-product-tab .c-sec-title__main {
          font-size: 1.8rem;
          text-align: center;
          line-height: 1.2; } }

.tab_item {
  display: none; }

.is-active-item {
  display: block;
  padding-top: 200px;
  margin-top: -200px; }

.p-product-industry {
  margin-top: 70px; }
  @media screen and (max-width: 767.9px) {
    .p-product-industry {
      margin-top: 40px; } }
  .p-product-industry_list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
    margin: 70px 0 0; }
    @media screen and (max-width: 767.9px) {
      .p-product-industry_list {
        margin-top: 40px;
        justify-content: center; } }
  .p-product-industry_item {
    width: 14.2%;
    text-align: center; }
    @media screen and (max-width: 767.9px) {
      .p-product-industry_item {
        width: 24%;
        margin-bottom: 10px;
        margin-left: 0.5%;
        margin-right: 0.5%; } }
  .p-product-industry-detail__list {
    margin-top: 30px; }
  .p-product-industry-detail__title {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.4;
    margin-top: 10px; }
    .p-product-industry-detail__title.-first {
      display: inline-block;
      border-bottom: 1px solid #000;
      font-size: 2rem; }
      @media screen and (max-width: 767.9px) {
        .p-product-industry-detail__title.-first {
          font-size: 1.8rem; } }
    @media screen and (max-width: 767.9px) {
      .p-product-industry-detail__title {
        font-size: 1.6rem; } }
  .p-product-industry-detail__item {
    font-size: 1.5rem;
    line-height: 1.75;
    position: relative;
    padding-left: 1em; }
    @media screen and (max-width: 767.9px) {
      .p-product-industry-detail__item {
        font-size: 1.4rem; } }
    .p-product-industry-detail__item .-point {
      font-weight: bold;
      color: #008ed2; }

.product-industry .p-product-under-ttl4 {
  font-size: 2.1rem; }
  @media screen and (max-width: 767.9px) {
    .product-industry .p-product-under-ttl4 {
      font-size: 1.8rem; } }

.p-news .l-main__inner {
  padding-top: 33px;
  padding-bottom: 80px; }
  @media (min-width: 768px) {
    .p-news .l-main__inner {
      padding-top: 73px;
      padding-bottom: 160px; } }

.p-news__paragraph {
  line-height: 1.8;
  margin-bottom: 33px; }
  @media (min-width: 768px) {
    .p-news__paragraph {
      line-height: 2.1;
      margin-bottom: 33px; } }
  .p-news__paragraph img {
    max-width: 450px; }
  .p-news__paragraph a {
    color: #007bff; }

.p-news__sec {
  margin-bottom: 39px; }
  @media (min-width: 768px) {
    .p-news__sec {
      margin-bottom: 84px; } }
  .p-news__sec .c-sec-title {
    margin-bottom: 31px; }
    @media (min-width: 768px) {
      .p-news__sec .c-sec-title {
        margin-bottom: 41px; } }

.p-news__title {
  font-size: 2.1rem;
  font-weight: bold;
  margin-bottom: 22px; }
  @media (min-width: 768px) {
    .p-news__title {
      font-size: 3rem;
      margin-bottom: 35px; } }
  .p-news__title.-privacy {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .p-news__title.-privacy {
        font-size: 2rem;
        margin-bottom: 20px; } }

.p-news__dl {
  display: flex; }
  .p-news__dl.__jc {
    justify-content: center; }
  .p-news__dl.__jsb {
    justify-content: space-between; }

.p-news__dd {
  text-align: center; }

.p-news__table {
  border-collapse: collapse;
  margin-bottom: 40px; }
  .p-news__table th,
  .p-news__table td {
    border: solid 1px #333;
    padding: 5px;
    text-align: center;
    vertical-align: middle; }
  .p-news__table th {
    background-color: #eee; }

.p-news__btn-group {
  margin-bottom: 50px; }
  .p-news__btn-group .c-btn {
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto; }

.p-news__list.-privacy {
  margin-bottom: 35px; }
  .p-news__list.-privacy dt {
    font-weight: bold; }
  .p-news__list.-privacy dd {
    margin-bottom: 10px; }

.p-news__comment {
  font-size: 1.2rem;
  margin-bottom: 40px; }

.p-news__ttl4 {
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .p-news__ttl4 {
      font-size: 2rem; } }

.p-news__ttl5 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .p-news__ttl5 {
      font-size: 1.8rem; } }

.p-news__link {
  text-decoration: underline;
  color: #008ed2; }

.p-news__bannar {
  display: block;
  max-width: 650px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px #eee;
  padding: 10px; }

.p-news__col2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0 0 60px; }
  .p-news__col2__item {
    width: 48%; }
    @media screen and (max-width: 767.9px) {
      .p-news__col2__item {
        width: 100%; } }
  .p-news__col2.__afs {
    align-items: flex-start; }

.p-news__youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%; }
  .p-news__youtube iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%; }

.__w100 {
  width: 100%; }

.__w90 {
  width: 90%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 767.9px) {
    .__w90 {
      width: 100%; } }

.__w80 {
  width: 80%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 767.9px) {
    .__w80 {
      width: 100%; } }

.__w70 {
  width: 70%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 767.9px) {
    .__w70 {
      width: 100%; } }

.__w60 {
  width: 60%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 767.9px) {
    .__w60 {
      width: 100%; } }

.__w50 {
  width: 50%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 767.9px) {
    .__w50 {
      width: 100%; } }

.__w40 {
  width: 30%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 767.9px) {
    .__w40 {
      width: 100%; } }

.__w30 {
  width: 30%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 767.9px) {
    .__w30 {
      width: 100%; } }

.__w20 {
  width: 20%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 767.9px) {
    .__w20 {
      width: 100%; } }

.__w10 {
  width: 10%;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 767.9px) {
    .__w10 {
      width: 100%; } }

.__bb {
  border: solid 1px #eee; }

.p10 {
  padding: 10px; }

.cpplus2021_sec {
  background: url(/assets/img/news/cpplus2021/cpplus2021_main.png) no-repeat top center;
  background-size: 100%;
  background-color: #333;
  padding-top: 40%;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 40px; }
  @media screen and (max-width: 767.9px) {
    .cpplus2021_sec {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 20px; } }

.cpplus2021_text {
  color: #fff;
  font-size: 2.4rem;
  font-weight: bold; }
  @media screen and (max-width: 767.9px) {
    .cpplus2021_text {
      font-size: 1.7rem; } }
