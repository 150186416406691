.c-pageup {
  width: 100%;
  max-width: $max-width;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 14px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  padding: 0 24px;

  &.-static {
    position: absolute;
    top: -43px;
    bottom: inherit;

    @include media-breakpoint-up(md) {
      top: -50px;
    }
  }

  body.-visible-pageup & {
    opacity: 1;
    visibility: visible;
  }

  @include media-breakpoint-up(md) {
    bottom: 20px;
    padding: 0 20px;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    background-color: #333;

    @include media-breakpoint-up(md) {
      width: 39px;
    }
  }

  &__img {}
}
