.c-list {

  &.-desc {
    list-style-type: disc;
    padding-left: 1.5em;
  }

  &__item {
    line-height: 2.1;
  }
}
