.c-lower-mainvis {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 158px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5px;

  @include media-breakpoint-up(md) {
    height: 400px;
    padding-top: 20px;
  }

  &.-company    {
    background-image: url("/assets/img/company/kv-sp.jpg");

    @include media-breakpoint-up(md) {
      background-image: url("/assets/img/company/kv.jpg");
    }
  }

  &.-cpplus2021 {
    background-image: url("/assets/img/news/cpplus2021/kv.jpg");
    height: 502px;

    @include media-breakpoint-up(md) {
      background-image: url("/assets/img/news/cpplus2021/kv.jpg");
      height: 660px;
    }
  }

  &.-product    {
    background-image: url("/assets/img/product/kv-sp.jpg");

    @include media-breakpoint-up(md) {
      background-image: url("/assets/img/product/kv.jpg");
      padding-right: 7px;
    }
  }

  &__title {
    font-size: 2.4rem;
    color: white;
    text-shadow: 0 0 10px #000, 0 0 10px #000, 0 0 10px #000;

    @include media-breakpoint-up(md) {
      font-size: 4rem;
    }
  }
}
