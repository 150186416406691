.p-news {

  .l-main__inner {
    padding-top: 33px;
    padding-bottom: 80px;

    @include media-breakpoint-up(md) {
      padding-top: 73px;
      padding-bottom: 160px;
    }
  }

  &__paragraph {
    line-height: 1.8;
    margin-bottom: 33px;

    @include media-breakpoint-up(md) {
      line-height: 2.1;
      margin-bottom: 33px;
    }

    img {
      max-width: 450px;
    }

    a {
      color: #007bff;
    }
  }

  &__sec {
    margin-bottom: 39px;

    @include media-breakpoint-up(md) {
      margin-bottom: 84px;
    }

    .c-sec-title {
      margin-bottom: 31px;

      @include media-breakpoint-up(md) {
        margin-bottom: 41px;
      }
    }
  }

  &__title {
    font-size: 2.1rem;
    font-weight: bold;
    margin-bottom: 22px;

    @include media-breakpoint-up(md) {
      font-size: 3rem;
      margin-bottom: 35px;
    }

    &.-privacy {
      font-size: 1.7rem;
      font-weight: bold;
      margin-bottom: 15px;

      @include media-breakpoint-up(md) {
        font-size: 2rem;
        margin-bottom: 20px;
      }
    }
  }

  &__dl {
    display: flex;

    &.__jc {
      justify-content: center;
    }

    &.__jsb {
      justify-content: space-between;
    }
  }

  &__dt {
  }

  &__dd {
    text-align: center;
  }

  &__table {
    border-collapse: collapse;
    margin-bottom: 40px;

    th,
    td {
      border: solid 1px #333;
      padding: 5px;
      text-align: center;
      vertical-align: middle;
    }

    th {
      background-color: #eee;
    }
  }

  &__btn-group {
    margin-bottom: 50px;

    .c-btn {
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__list {

    &.-privacy {
      margin-bottom: 35px;

      dt {
        font-weight: bold;
      }

      dd {
        margin-bottom: 10px;
      }
    }
  }

  &__comment {
    font-size: 1.2rem;
    margin-bottom: 40px;
  }

  &__ttl4 {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
  }

  &__ttl5 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      font-size: 1.8rem;
    }
  }

  &__link {
    text-decoration: underline;
    color: #008ed2;
  }

  &__bannar {
    display: block;
    max-width: 650px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px #eee;
    padding: 10px;
  }

  &__col2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0 0 60px;

    &__item {
      width: 48%;

      @include sp {
        width: 100%;
      }
    }

    &.__afs {
      align-items: flex-start;
    }
  }

  &__youtube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.__w100 {
  width: 100%;
}

.__w90 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  @include sp {
    width: 100%;
  }
}

.__w80 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  @include sp {
    width: 100%;
  }
}

.__w70 {
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  @include sp {
    width: 100%;
  }
}

.__w60 {
  width: 60%;
  margin-left: auto;
  margin-right: auto;

  @include sp {
    width: 100%;
  }
}

.__w50 {
  width: 50%;
  margin-left: auto;
  margin-right: auto;

  @include sp {
    width: 100%;
  }
}

.__w40 {
  width: 30%;
  margin-left: auto;
  margin-right: auto;

  @include sp {
    width: 100%;
  }
}

.__w30 {
  width: 30%;
  margin-left: auto;
  margin-right: auto;

  @include sp {
    width: 100%;
  }
}

.__w20 {
  width: 20%;
  margin-left: auto;
  margin-right: auto;

  @include sp {
    width: 100%;
  }
}

.__w10 {
  width: 10%;
  margin-left: auto;
  margin-right: auto;

  @include sp {
    width: 100%;
  }
}

.__bb {
  border: solid 1px #eee;
}

.p10 {
  padding: 10px;
}


.cpplus2021 {

  &_sec {
    background: url(/assets/img/news/cpplus2021/cpplus2021_main.png) no-repeat top center;
    background-size: 100%;
    background-color: #333;
    padding-top: 40%;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 40px;

    @include sp {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 20px;
    }
  }

  &_text {
    color: #fff;
    font-size: 2.4rem;
    font-weight: bold;

    @include sp {
      font-size: 1.7rem;
    }
  }
}
