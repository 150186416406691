.p-product {

  .l-main__inner {
    padding-top: 33px;
    padding-bottom: 80px;

    @include media-breakpoint-up(md) {
      padding-top: 73px;
      padding-bottom: 160px;
    }
  }

  &__sec {

    &.-top {
      margin-bottom: 39px;

      @include media-breakpoint-up(md) {
        margin-bottom: 84px;
      }

      .c-sec-title {
        margin-bottom: 31px;

        @include media-breakpoint-up(md) {
          margin-bottom: 41px;
        }
      }
    }
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 0;
  }

  &__item {
    width: 100%;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 30px;
    margin-bottom: 25px;
    position: relative;

    @include media-breakpoint-up(md) {
      width: 32%;
      margin-right: 2%;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__ttl4 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }

  &__image {
    width: 56.6%;
    margin-left: auto;
    margin-right: auto;
    min-height: 0%;

    &.-sd {
      width: 76%;
    }

    img {
      width: 100%;
      vertical-align: bottom;
    }
  }

  &__btn {
    width: 180px;
    height: 43px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-decoration: none;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 0.4;
      background: #fff;
    }

    &.-main:hover {
      background: none;
      opacity: 1;
    }
  }
}

.p-product-under {

  .l-main__inner {
    padding-top: 33px;
    padding-bottom: 80px;

    @include media-breakpoint-up(md) {
      padding-top: 73px;
      padding-bottom: 160px;
    }
  }

  &__list {
    padding: 0;
  }

  &__item {
    padding-top: 65px;
    padding-bottom: 100px;
    padding-left: 20px;
    border-bottom: 1px solid #999;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding-top: 30px;
      padding-bottom: 15px;
      padding-left: 0;
    }
  }

  &__image {
    width: 23%;
    position: relative;
    height: 235px;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }

    @include media-breakpoint-down(sm) {
      width: 80%;
      height: auto;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;

      img {
        position: static;
      }
    }
  }

  &-detail {
    width: 73%;
    margin-left: 4%;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-left: 0;
    }

    &__list {
      padding: 0;
      margin: 20px 0 0;
      list-style: none;
    }

    &__item {
      font-size: 1.5rem;
      line-height: 1.75;
      position: relative;

      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
      }
    }
  }

  &-lineup {

    &__ttl4 {
      font-size: 1.8rem;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
      margin-top: 20px;
      margin-bottom: 10px;

      @include sp {
        font-size: 1.6rem;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      justify-content: flex-start;
      align-items: stretch;

      @include sp {
        justify-content: center;
      }
    }

    &__item {
      width: 32%;
      border: solid 1px #ddd;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px;
      margin-right: 2%;
      position: relative;
      margin-bottom: 10px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      img {
        max-width: 150px;
      }
    }

    &__ttl5 {
      font-size: 1.5rem;
      font-weight: bold;
    }

    &__link {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-decoration: none;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 0.4;
        background: #fff;
      }
    }

    &__table {
      width: 100%;
      border-collapse: collapse;
      font-size: 1.6rem;
      margin-top: 40px;

      &.__top {
        margin-top: 0;
      }

      @include media-breakpoint-down(sm) {
        font-size: 1rem;
        word-break: break-word;
        word-wrap: break-word;
        table-layout: fixed;
        margin-top: 20px;
      }

      thead {

        th {
          background: #333;
          color: #fff;
          font-weight: bold;
          padding: 10px;
          text-align: center;

          @include sp {
            padding: 1px;
          }
        }
      }

      th,
      td {
        border: solid 1px #999;
        text-align: center;
        vertical-align: middle;
        padding: 5px;
        word-break: break-word;

        img {
          max-width: 150px;
        }
      }

      th {
        background: #999;
        color: #fff;
        text-align: left;
        padding-left: 20px;
      }
    }

    &__text {
      font-size: 1.2rem;
      margin-top: 5px;
    }

    &__download {
      color: #008ed2;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: url(/assets/img/product/ico_download.svg);
        margin-top: 5px;
      }

      &:hover {
        opacity: 0.65;
        color: #008ed2;
      }
    }
  }

  &-ttl4 {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.388;

    @include media-breakpoint-down(sm) {
      font-size: 1.7rem;
    }

    span {
      display: block;
      font-size: 1.5rem;
      line-height: 1.666;
      font-weight: normal;

      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
      }
    }
  }

  &-ttl5 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.4;
    margin-top: 15px;
    margin-bottom: 10px;

    @include media-breakpoint-down(sm) {
      font-size: 1.8rem;
    }
  }

  &-comment {
    font-size: 1.2rem;
  }

  &-linkarea {
    position: absolute;
    width: 495px;
    height: 43px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    bottom: 30px;
    right: 30px;

    @include media-breakpoint-down(sm) {
      position: static;
      width: 100%;
      height: auto;
    }
  }

  &__link {
    display: flex;
    height: 43px;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    line-height: 1;
    position: relative;

    &::after {
      content: "";
      background: url(/assets/img/common/icon_arrow-right-white.svg) no-repeat center;
      width: 10px;
      height: 13px;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }

    &:hover {
      opacity: 0.65;
      color: #fff;
    }

    &.-support {
      background: #92959a;
      width: 300px;
      margin-right: 15px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 0;
      }
    }

    &.-detail {
      background: #008ed2;
      width: 180px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1.6rem;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      word-break: break-word;
      word-wrap: break-word;
      table-layout: fixed;
    }

    th,
    td {
      border: solid 1px #999;
      text-align: center;
      vertical-align: middle;
      padding: 5px;
      word-break: break-word;

      span {
        font-size: 12px;

        @include sp {
          font-size: 9px;
        }
      }
    }

    &-ttl {

      th {
        background: #333;
        color: #fff;
        font-weight: bold;
        padding: 10px;

        @include sp {
          padding: 1px;
        }

        &:first-child {
          width: 11%;

          @include sp {
            width: 23%;
          }
        }

        &:nth-child(2) {
          width: 11%;

          @include sp {
            width: 19%;
          }
        }

        &:nth-child(3) {
          width: 78%;

          @include sp {
            width: 58%;
          }
        }
      }
    }

    &-data {

      th {
        background: #e6e6e6;
        font-weight: bold;
        padding: 10px;
        width: 26%;

        @include media-breakpoint-down(sm) {
          padding: 1px;
          width: 19.333%;
          vertical-align: top;
        }

        img {
          width: auto;
          display: block;
          margin-left: auto;
          margin-right: auto;

          @include media-breakpoint-down(sm) {
            max-width: 44px;
          }

          &.v-sp {
            display: none;

            @include media-breakpoint-down(sm) {
              display: block;
            }
          }

          &.v-pc {
            display: block;

            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }
      }
    }

    &-data02 {

      th {
        background: #e6e6e6;
        font-weight: bold;
        word-break: break-all;
      }
    }

    &-link {
      display: block;
      width: 55.555%;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 10px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 5px;
      }

      img {
        width: 100%;
      }
    }
  }
}

.p-product-tab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  flex-wrap: wrap;
  font-weight: bold;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;

  @include sp {
    height: 70px;
    font-size: 14px;
  }

  &-btn {
    width: 49.5%;
    height: 100%;

    a.tab_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: solid 1px $theme-color;
      transition: 0.5s ease;
      position: relative;
      color: $theme-color;

      &:hover {
        background: $theme-color;

        .c-sec-title__main {
          color: #fff;
        }
      }

      &.is-active-btn {
        color: #fff;
        background: $theme-color;

        .c-sec-title__main {
          color: #fff;
        }
      }
    }
  }

  .c-sec-title {
    margin-bottom: 0 !important;
    align-items: center;

    &__main {
      margin-right: 0;
      transform: translateY(0);

      @include sp {
        font-size: 1.8rem;
        text-align: center;
        line-height: 1.2;
      }
    }
  }
}

.tab_item {
  display: none;
}

.is-active-item {
  display: block;
  padding-top: 200px;
  margin-top: -200px;
}


.p-product-industry {
  margin-top: 70px;

  @include sp {
    margin-top: 40px;
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
    margin: 70px 0 0;

    @include sp {
      margin-top: 40px;
      justify-content: center;
    }
  }

  &_item {
    width: 14.2%;
    text-align: center;

    @include sp {
      width: 24%;
      margin-bottom: 10px;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }
  }

  &-detail {

    &__list {
      margin-top: 30px;
    }

    &__title {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.4;
      margin-top: 10px;

      &.-first {
        display: inline-block;
        border-bottom: 1px solid #000;
        font-size: 2rem;

        @include sp {
          font-size: 1.8rem;
        }
      }

      @include sp {
        font-size: 1.6rem;
      }
    }

    &__item {
      font-size: 1.5rem;
      line-height: 1.75;
      position: relative;
      padding-left: 1em;

      @include sp {
        font-size: 1.4rem;
      }

      .-point {
        font-weight: bold;
        color: $theme-color;
      }
    }
  }
}

.product-industry {

  .p-product-under-ttl4 {
    font-size: 2.1rem;

    @include sp {
      font-size: 1.8rem;
    }
  }
}
