.c-btn {
  width: 100%;
  height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: black;
  color: white !important;
  font-size: 1.6rem;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    width: 420px;
    height: 64px;
  }

  &.-primary {
    background-color: $theme-color;
  }

  &.-top {
    @include media-breakpoint-down(sm) {
      height: 52px;
      font-size: 1.3rem;
    }
  }

  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    pointer-events: none;
  }

  &:not(.-anchor)::after {
    width: 8px;
    height: 11px;
    top: 50%;
    right: 15px;
    background-image: url("/assets/img/common/icon_arrow-right-white.svg");
    transform: translateY(-50%);

    @include media-breakpoint-up(md) {
      width: 10px;
      height: 13px;
      right: 18px;
    }
  }

  &.-anchor {
    height: 85px;
    font-size: 1.8rem;
    font-weight: bold;
    padding-bottom: 15px;

    @include media-breakpoint-up(md) {
      height: 104px;
      font-size: 2.3rem;
      padding-bottom: 17px;
    }

    &::after {
      width: 13px;
      height: 9px;
      bottom: 18px;
      left: 50%;
      background-image: url("/assets/img/common/icon_arrow-bottom-white.svg");
      transform: translateX(-50%);

      @include media-breakpoint-up(md) {
        width: 16px;
        height: 10px;
        bottom: 22px;
      }
    }
  }
}
