/* =============================================
 * Header
 * ============================================= */

#tmpl_skiptxt {
  position: absolute;
  top: -9999px;
}

#tmpl_skiptxt:focus,
#tmpl_skiptxt:hover {
  top: 0;
  color: #fff;
  z-index: 999;
}

.l-header {

  &__en-link {
    font-size: 1.3rem;
    color: black !important;
    margin: 0 8px 0 auto;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
