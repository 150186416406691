// ==============================================
// ブレイクポイント設定
// ==============================================
// これ以上の場合にPC表示になる
$breakpoint-pc: 768px;
$max-width: 1230px;
$min-width: 1090px;

// ==============================================
// フォント設定
// ==============================================
// ベースのフォントサイズ 1rem = 10px
$base-font-size: 1.6rem;
$base-sp-font-size: 1.3rem;

// ベースのフォントカラー
$base-font-color: #000;

// ベースのリンクカラー
$base-link-color: #000;

// ベースの行間
$base-line-height: 1.7;

// ベースのフォント
$base-font-family: 'Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3', 'Hiragino Sans','ヒラギノ角ゴシック',YuGothic,'Yu Gothic','メイリオ',Meiryo,'ＭＳ Ｐゴシック','MS PGothic',sans-serif;

// ==============================================
// 色設定
// ==============================================
// テーマカラー
$theme-color: #008ed2;
$bg-light-gray-color: #f2f2f2;
$border-light-gray-color: #b3b3b3;
$border-light-gray2-color: #ccc;
$border-gray-color: #999;
$border-dark-gray-color: #333;
$dot-gray-color: #b3b3b3;
$dot-red-color: #ff1d25;
$drawer-menu-gray-color: #212727;
