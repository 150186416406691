/* =============================================
* Utilities
* ============================================= */

// ---------------------------
// 表示・非表示
// h-sp/v-pc -> PCでのみ表示
// v-sp/h-pc -> SPでのみ表示
//
// ※hide-sp/view-spの略
// ---------------------------
@include pc {

  .v-sp,
  .h-pc {
    display: none !important;
  }
}

@include sp {

  .v-pc,
  .h-sp {
    display: none !important;
  }
}

// ---------------------------
// 汎用スタイル
// ---------------------------

/* ---- display ---- */
html body .db{display: block;}
html body .dib{display: inline-block;}
html body .dn{display: none;}

// ---------------------------
// テキスト系スタイル
// ---------------------------

/* ---- テキスト位置揃え ---- */
html body .tac{text-align: center;}
html body .tal{text-align: left;}
html body .tar{text-align: right;}

html body .fwb{font-weight: bold;}
html body .fwn{font-weight: normal;}

/* ---- テキスト装飾 ---- */
html body .tdn{text-decoration: none;}
html body .tdu{text-decoration: underline;}

/* ---- テキストサイズ ---- */
html body .fs10{font-size: 1rem;}
html body .fs11{font-size: 1.1rem;}
html body .fs12{font-size: 1.2rem;}
html body .fs13{font-size: 1.3rem;}
html body .fs14{font-size: 1.4rem;}
html body .fs15{font-size: 1.5rem;}
html body .fs16{font-size: 1.6rem;}
html body .fs17{font-size: 1.7rem;}
html body .fs18{font-size: 1.8rem;}
html body .fs19{font-size: 1.9rem;}
html body .fs20{font-size: 2rem;}
html body .fs21{font-size: 2.1rem;}
html body .fs22{font-size: 2.2rem;}
html body .fs23{font-size: 2.3rem;}
html body .fs24{font-size: 2.4rem;}
html body .fs25{font-size: 2.5rem;}
html body .fs26{font-size: 2.6rem;}
html body .fs27{font-size: 2.7rem;}
html body .fs28{font-size: 2.8rem;}
html body .fs29{font-size: 2.9rem;}
html body .fs30{font-size: 3rem;}
html body .fs31{font-size: 3.1rem;}
html body .fs32{font-size: 3.2rem;}
html body .fs33{font-size: 3.3rem;}
html body .fs34{font-size: 3.4rem;}
html body .fs35{font-size: 3.5rem;}
html body .fs36{font-size: 3.6rem;}
html body .fs37{font-size: 3.7rem;}
html body .fs38{font-size: 3.8rem;}
html body .fs39{font-size: 3.9rem;}
html body .fs40{font-size: 4rem;}
html body .fs41{font-size: 4.1rem;}
html body .fs42{font-size: 4.2rem;}
html body .fs43{font-size: 4.3rem;}
html body .fs44{font-size: 4.4rem;}
html body .fs45{font-size: 4.5rem;}
html body .fs46{font-size: 4.6rem;}
html body .fs55{font-size: 5.5rem;}

@include pc {
  html body .pcfs10{font-size: 1rem;}
  html body .pcfs11{font-size: 1.1rem;}
  html body .pcfs12{font-size: 1.2rem;}
  html body .pcfs13{font-size: 1.3rem;}
  html body .pcfs14{font-size: 1.4rem;}
  html body .pcfs15{font-size: 1.5rem;}
  html body .pcfs16{font-size: 1.6rem;}
  html body .pcfs17{font-size: 1.7rem;}
  html body .pcfs18{font-size: 1.8rem;}
  html body .pcfs19{font-size: 1.9rem;}
  html body .pcfs20{font-size: 2rem;}
  html body .pcfs21{font-size: 2.1rem;}
  html body .pcfs22{font-size: 2.2rem;}
  html body .pcfs23{font-size: 2.3rem;}
  html body .pcfs24{font-size: 2.4rem;}
  html body .pcfs25{font-size: 2.5rem;}
  html body .pcfs26{font-size: 2.6rem;}
  html body .pcfs27{font-size: 2.7rem;}
  html body .pcfs28{font-size: 2.8rem;}
  html body .pcfs29{font-size: 2.9rem;}
  html body .pcfs30{font-size: 3rem;}
  html body .pcfs31{font-size: 3.1rem;}
  html body .pcfs32{font-size: 3.2rem;}
  html body .pcfs33{font-size: 3.3rem;}
  html body .pcfs34{font-size: 3.4rem;}
  html body .pcfs35{font-size: 3.5rem;}
  html body .pcfs36{font-size: 3.6rem;}
  html body .pcfs37{font-size: 3.7rem;}
  html body .pcfs38{font-size: 3.8rem;}
  html body .pcfs39{font-size: 3.9rem;}
  html body .pcfs40{font-size: 4rem;}
  html body .pcfs41{font-size: 4.1rem;}
  html body .pcfs42{font-size: 4.2rem;}
  html body .pcfs43{font-size: 4.3rem;}
  html body .pcfs44{font-size: 4.4rem;}
  html body .pcfs45{font-size: 4.5rem;}
  html body .pcfs46{font-size: 4.6rem;}
}

@include sp {
  html body .spfs10{font-size: 1rem;}
  html body .spfs11{font-size: 1.1rem;}
  html body .spfs12{font-size: 1.2rem;}
  html body .spfs13{font-size: 1.3rem;}
  html body .spfs14{font-size: 1.4rem;}
  html body .spfs15{font-size: 1.5rem;}
  html body .spfs16{font-size: 1.6rem;}
  html body .spfs17{font-size: 1.7rem;}
  html body .spfs18{font-size: 1.8rem;}
  html body .spfs19{font-size: 1.9rem;}
  html body .spfs20{font-size: 2rem;}
  html body .spfs21{font-size: 2.1rem;}
  html body .spfs22{font-size: 2.2rem;}
  html body .spfs23{font-size: 2.3rem;}
  html body .spfs24{font-size: 2.4rem;}
  html body .spfs25{font-size: 2.5rem;}
  html body .spfs26{font-size: 2.6rem;}
  html body .spfs27{font-size: 2.7rem;}
  html body .spfs28{font-size: 2.8rem;}
  html body .spfs29{font-size: 2.9rem;}
  html body .spfs30{font-size: 3rem;}
  html body .spfs31{font-size: 3.1rem;}
  html body .spfs32{font-size: 3.2rem;}
  html body .spfs33{font-size: 3.3rem;}
  html body .spfs34{font-size: 3.4rem;}
  html body .spfs35{font-size: 3.5rem;}
  html body .spfs36{font-size: 3.6rem;}
  html body .spfs37{font-size: 3.7rem;}
  html body .spfs38{font-size: 3.8rem;}
  html body .spfs39{font-size: 3.9rem;}
  html body .spfs40{font-size: 4rem;}
  html body .spfs41{font-size: 4.1rem;}
  html body .spfs42{font-size: 4.2rem;}
  html body .spfs43{font-size: 4.3rem;}
  html body .spfs44{font-size: 4.4rem;}
  html body .spfs45{font-size: 4.5rem;}
  html body .spfs46{font-size: 4.6rem;}
}

@for $i from 0 through 20 {
  $m: $i * 5;
  $unit: "px";

  @if $i==0{$unit: "";}

  html body .mt#{$m}{margin-top: #{$m}#{$unit};}
  html body .mb#{$m}{margin-bottom: #{$m}#{$unit};}
  html body .ml#{$m}{margin-left: #{$m}#{$unit};}
  html body .mr#{$m}{margin-right: #{$m}#{$unit};}
  html body .pt#{$m}{padding-top: #{$m}#{$unit};}
  html body .pb#{$m}{padding-bottom: #{$m}#{$unit};}
  html body .pl#{$m}{padding-left: #{$m}#{$unit};}
  html body .pr#{$m}{padding-right: #{$m}#{$unit};}
}

@include pc{
  @for $i from 0 through 20 {
    $m: $i * 5;
    $unit: "px";

    @if $i==0{$unit: "";}
    html body .pcmt#{$m}{margin-top: #{$m}#{$unit} !important;}
    html body .pcmb#{$m}{margin-bottom: #{$m}#{$unit} !important;}
    html body .pcml#{$m}{margin-left: #{$m}#{$unit} !important;}
    html body .pcmr#{$m}{margin-right: #{$m}#{$unit} !important;}
    html body .pcpt#{$m}{padding-top: #{$m}#{$unit} !important;}
    html body .pcpb#{$m}{padding-bottom: #{$m}#{$unit} !important;}
    html body .pcpl#{$m}{padding-left: #{$m}#{$unit} !important;}
    html body .pcpr#{$m}{padding-right: #{$m}#{$unit} !important;}
  }
}

@include sp{
  @for $i from 0 through 20 {
    $m: $i * 5;
    $unit: "px";

    @if $i==0{$unit: "";}
    html body .spmt#{$m}{margin-top: #{$m}#{$unit};}
    html body .spmb#{$m}{margin-bottom: #{$m}#{$unit};}
    html body .spml#{$m}{margin-left: #{$m}#{$unit};}
    html body .spmr#{$m}{margin-right: #{$m}#{$unit};}
    html body .sppt#{$m}{padding-top: #{$m}#{$unit};}
    html body .sppb#{$m}{padding-bottom: #{$m}#{$unit};}
    html body .sppl#{$m}{padding-left: #{$m}#{$unit};}
    html body .sppr#{$m}{padding-right: #{$m}#{$unit};}
  }
}

/* ---- テキストカラー ---- */

.fc-red {
  color: #f00;
}

/* ---- 画像リサイズ ---- */

.resize {
  width: 100%;
}

.resize-sp {
  @include sp {
    width: 100%;
  }
}

.lh1-2 {
  line-height: 1.2;
}
