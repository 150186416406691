.c-sec-title {
  display: flex;
  align-items: flex-end;

  &__main {
    font-size: 3rem;
    color: $theme-color;
    line-height: 1;
    margin-right: 14px;
    transform: translateY(5px);

    @include media-breakpoint-up(md) {
      font-size: 4rem;
      margin-right: 16px;
    }
  }

  &__main-img {

    &.-vision {
      width: 138px;

      @include media-breakpoint-up(md) {
        width: 170px;
        margin-right: 19px;
      }
    }

    &.-news {
      width: 124px;

      @include media-breakpoint-up(md) {
        width: 152px;
        margin-right: 17px;
      }
    }
  }

  &__sub {
    font-size: 1.5rem;
    line-height: 1;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
  }

  &.-large {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .c-sec-title__main-img {
        margin-bottom: 18px;
      }
    }
  }

  &.-white {

    .c-sec-title__main {
      color: white;
    }

    .c-sec-title__sub {
      color: white;
    }
  }

  &-under {
    font-size: 4rem;
    line-height: 1.75;
    padding-bottom: 40px;
    border-bottom: 1px solid #999;

    &.-support {
      font-size: 3rem;
      font-weight: bold;
      border-bottom: none;
    }
  }
}
